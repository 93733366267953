import { useEffect } from 'react'
import { ScreenRoutePath, useRouterNavigate } from 'src/App/router/hooks'
import ScreenContainer from 'src/_shared/components/ScreenContainer'
import Spinner from 'src/_shared/components/Spinner'
import useLiveSession from 'src/_shared/hooks/useLiveSession'

const CheckInRedirectScreen = (): JSX.Element | null => {
	const navigate = useRouterNavigate()

	const { chargerDetails, status: liveSessionStatus } = useLiveSession()

	useEffect((): void => {
		if (liveSessionStatus !== 'pending') {
			// If the user has an ongoing Session, redirect to the Charger Screen.
			if (chargerDetails) {
				const { cpoEntityCode, locationUid, evseUid, connectorUid } = chargerDetails
				navigate([ScreenRoutePath.Charger, cpoEntityCode, locationUid, evseUid, connectorUid], {
					replace: true
				})
			}
			// Otherwise, redirect to the Check-In Screen (regardless of the `liveSessionStatus`).
			else {
				navigate(ScreenRoutePath.CheckIn, { replace: true })
			}
		}
	}, [chargerDetails, liveSessionStatus, navigate])

	return (
		<ScreenContainer
			contentViewProps={{
				className: 'px-5 py-6'
			}}
			hideBottomBar
		>
			{liveSessionStatus === 'pending' && (
				<div className="flex flex-grow flex-col items-center justify-center">
					<Spinner />
				</div>
			)}
		</ScreenContainer>
	)
}

export default CheckInRedirectScreen
