import { memo, ReactNode } from 'react'
import { classNames } from 'src/_shared/utils/elements'
import { formatDataTestId } from 'src/_shared/utils/string'

import LabelValueListItem, { LabelValueListItemProps } from './LabelValueListItem'

interface LabelValueListProps {
	className?: string
	labelValueList: LabelValueListItemProps[]
	title: ReactNode
}

const LabelValueList = ({ className, title, labelValueList }: LabelValueListProps): JSX.Element => {
	return (
		<div className={classNames(className, 'rounded-lg bg-grayscale-200 p-4')}>
			{/* Title */}
			<div className="mb-4">
				<span
					data-testid={formatDataTestId(['rd-text-title', title?.toString().toLowerCase() ?? ''])}
					className="body-2-semibold text-typography-primary"
				>
					{title}
				</span>
			</div>
			{/* List */}
			{labelValueList.map(
				(labelValueListItemProps, index): JSX.Element => (
					<LabelValueListItem key={index} {...labelValueListItemProps} />
				)
			)}
		</div>
	)
}

const MemoisedLabelValueList = memo(LabelValueList)

export default MemoisedLabelValueList
