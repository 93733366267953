interface HistoryLoaderProps {
	description: string
}

const HistoryLoader = ({ description }: HistoryLoaderProps): JSX.Element => {
	return (
		<div className="flex items-center justify-center border-t border-divider-primary py-4">
			<p className="body-1-normal text-typography-tertiary">{description}</p>
		</div>
	)
}

export default HistoryLoader
