import { useCallback, useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Navigate, useParams } from 'react-router-dom'
import { ScreenRoutePath, useQueryParams, useRouterNavigate } from 'src/App/router/hooks'
import Notice from 'src/_shared/components/Notice'
import PoweredByFooter from 'src/_shared/components/PoweredByFooter'
import ScreenContainer from 'src/_shared/components/ScreenContainer'
import Spinner from 'src/_shared/components/Spinner'
import ChargerAcIcon from 'src/_shared/components/_icons/ChargerAcIcon'
import ChargerChademoDcIcon from 'src/_shared/components/_icons/ChargerChademoDcIcon'
import ChargerDcIcon from 'src/_shared/components/_icons/ChargerDcIcon'
import LightningBoltFilledIcon from 'src/_shared/components/_icons/LightningBoltFilledIcon'
import LocationDetailsClockIcon from 'src/_shared/components/_icons/LocationDetailsClockIcon'
import { OmniConnectorPowerType, OmniConnectorStandard } from 'src/_shared/enums/omni'
import { useLocationQuery } from 'src/_shared/queries/locations'
import { OmniConnector } from 'src/_shared/types/omni'
import { isConnectorAvailable } from 'src/_shared/utils/charging'
import { getMatchingChargersByQrIdentifier } from 'src/_shared/utils/checkIn'
import { classNames } from 'src/_shared/utils/elements'

import { CheckInLocationScreenQueryParams, CheckInLocationScreenRouteParams } from './types'
import { formatConnectorType } from './utils'

// 1 Minute
const DEFAULT_REFETCH_INTERVAL = 60000

const CheckInLocationScreen = (): JSX.Element => {
	const { locationUid } = useParams() as unknown as CheckInLocationScreenRouteParams

	const [{ qr_identifier: qrIdentifier }] = useQueryParams<CheckInLocationScreenQueryParams>()

	const intl = useIntl()

	const navigate = useRouterNavigate()

	const {
		data: location = null,
		error: locationError,
		status: locationQueryStatus
	} = useLocationQuery(
		{ locationUid },
		{
			enabled: !!locationUid,
			refetchInterval: (query): false | number => {
				// Disable refetching if there is still no data after repeated errors.
				if (!query.state.data && query.state.errorUpdateCount > 0) {
					return false
				}
				return DEFAULT_REFETCH_INTERVAL
			},
			refetchOnWindowFocus: (query): boolean => {
				// Disable refetching if there is still no data after repeated errors.
				if (!query.state.data && query.state.errorUpdateCount > 0) {
					return false
				}
				return true
			}
		}
	)

	const errorMessage = locationError?.response?.data.message

	/**
	 * Grouped up connectors based on Charger ID key (regardless of EVSE).
	 */
	const chargerIdToConnectorsMap = useMemo((): Partial<
		Record<string, { evseUid?: string; connector: OmniConnector }[]>
	> | null => {
		if (location?.evses && qrIdentifier) {
			const { matchedEvses } = getMatchingChargersByQrIdentifier(location.evses, qrIdentifier)
			if (matchedEvses.length > 0) {
				const chargerIdToConnectorsMap: Partial<
					Record<string, { evseUid?: string; connector: OmniConnector }[]>
				> = {}
				matchedEvses.forEach((evse): void => {
					const chargerId = evse.physical_reference ?? evse.evse_id ?? ''
					if (!chargerIdToConnectorsMap[chargerId]) {
						chargerIdToConnectorsMap[chargerId] = []
					}
					chargerIdToConnectorsMap[chargerId]?.push(
						...(evse.connectors ?? []).map(
							(connector): { evseUid: string; connector: OmniConnector } => ({
								evseUid: evse.uid ?? '',
								connector
							})
						)
					)
				})
				return chargerIdToConnectorsMap
			}
		}
		return null
	}, [location?.evses, qrIdentifier])

	const handleConnectorClick = useCallback(
		(entityCode = '', locationUid = '', evseUid = '', connectorUid = '') =>
			(): void => {
				if (entityCode && locationUid && evseUid && connectorUid) {
					navigate([ScreenRoutePath.Charger, entityCode, locationUid, evseUid, connectorUid])
				}
			},
		[navigate]
	)

	if (!qrIdentifier) {
		return <Navigate to={ScreenRoutePath.Root} />
	}
	return (
		<ScreenContainer contentViewProps={{ className: 'px-5 py-6' }} hideBottomBar>
			{locationQueryStatus === 'pending' ? (
				<div className="flex flex-grow flex-col items-center justify-center">
					<Spinner />
				</div>
			) : location && chargerIdToConnectorsMap ? (
				<>
					{/* Location Details */}
					{/* FUTURE TODO: Sync-up with Map Rewrite by extracting this out as a common component. */}
					<div className="mb-3 flex flex-col">
						<h1 className="mb-0.5 text-typography-primary" data-testid="cils-location-details-name">
							{location.name ?? '-'}
						</h1>
						<p
							className="body-1-normal mb-1.5 text-typography-secondary"
							data-testid="cils-location-details-address"
						>
							{location.address ?? '-'}
						</p>
						<div className="mb-2 flex items-center space-x-3">
							{location.opening_times?.twentyfourseven === true && (
								<div
									className="flex items-center space-x-1"
									data-testid="cils-location-details-24-hours"
								>
									<LocationDetailsClockIcon className="h-4 w-4 text-typography-tertiary" />
									<p className="body-1-normal text-typography-secondary">
										<FormattedMessage
											id="CheckInLocationScreen.TagTwentyFourHours"
											defaultMessage="24 Hours"
										/>
									</p>
								</div>
							)}
							<div className="flex flex-row items-center space-x-1">
								<div className="flex h-3.5 w-3.5 items-center justify-center rounded-full bg-success-100">
									<LightningBoltFilledIcon className="h-2 w-2 text-success-400" />
								</div>
								<p className="body-1-medium text-success-400">
									<FormattedMessage
										id="CheckInLocationScreen.ConnectorStatusTextAvailable"
										defaultMessage="Available"
									/>
								</p>
							</div>
							<div className="flex flex-row items-center space-x-1">
								<div className="flex h-3.5 w-3.5 items-center justify-center rounded-full bg-error-100">
									<LightningBoltFilledIcon className="h-2 w-2 text-error-300" />
								</div>
								<p className="body-1-medium text-error-300">
									<FormattedMessage
										id="CheckInLocationScreen.ConnectorStatusTextUnavailable"
										defaultMessage="Unavailable"
									/>
								</p>
							</div>
						</div>
					</div>
					{/* Content */}
					<div className="flex flex-grow flex-col">
						<h1 className="mb-2 text-typography-primary">
							<FormattedMessage
								id="CheckInLocationScreen.HeaderMultipleConnectors"
								defaultMessage="This Charger Has Multiple Connectors"
							/>
						</h1>
						<p className="body-2-normal mb-5 text-typography-primary">
							<FormattedMessage
								id="CheckInLocationScreen.DescriptionSelectConnector"
								defaultMessage="Please select the connector that you will charge with."
							/>
						</p>
						{/* Charger ID List */}
						{Object.entries(chargerIdToConnectorsMap).map(
							([chargerId, connectors], i): JSX.Element => {
								return (
									<div
										key={i}
										className="mb-3 flex w-full flex-col space-y-3 rounded-lg border border-divider-primary bg-grayscale-300/80 px-4 py-3"
										data-testid={`cils-card-${i}`}
									>
										{/* Card Title */}
										<p
											className="body-2-medium text-typography-primary"
											data-testid={`cils-card-title-${i}`}
										>
											<FormattedMessage
												id="CheckInLocationScreen.CardTitleChargerId"
												defaultMessage="Charger ID: {chargerId}"
												values={{
													chargerId
												}}
											/>
										</p>
										{/* Connectors List */}
										<div
											className="grid grid-cols-2 gap-x-2 gap-y-2"
											data-testid={`cils-card-connectors-list-${i}`}
										>
											{connectors?.map(({ evseUid, connector }, j): JSX.Element => {
												const { max_electric_power = 0, power_type, standard } = connector

												const connectorOrder = j + 1

												const connectorPower = `${max_electric_power / 1000} kW`

												const connectorType = formatConnectorType(standard)

												const isAvailable = isConnectorAvailable(connector)

												return (
													<button
														key={j}
														data-testid={`cils-card-${i}-btn-connector-${j}`}
														className="relative flex cursor-pointer items-center rounded-2xl border-2 border-divider-primary bg-grayscale-100 p-2"
														onClick={handleConnectorClick(
															location.entity_code,
															location.uid,
															evseUid,
															connector.uid
														)}
													>
														{/* Connector Order */}
														<div className="absolute right-[-2px] top-[-2px] flex h-6 w-6 items-center justify-center rounded-bl-md rounded-tr-2xl bg-grayscale-700">
															<span
																className="caption-3-medium text-grayscale-100"
																data-testid={`cils-card-${i}-btn-connector-${j}-order`}
															>
																{connectorOrder}
															</span>
														</div>
														{/* AC/DC Icon */}
														<div
															data-testid={`cils-card-${i}-btn-connector-${j}-icon`}
															className={classNames(
																'mr-1.5 flex h-9 min-w-9 items-center justify-center rounded-full border-[3px]',
																isAvailable ? 'border-success-400' : 'border-error-300'
															)}
														>
															{/* FUTURE TODO: Sync-up with Map Rewrite by extracting this out as a common component/function. */}
															{((): JSX.Element => {
																if (power_type === OmniConnectorPowerType.Dc) {
																	switch (standard) {
																		case OmniConnectorStandard.Chademo:
																			return (
																				<ChargerChademoDcIcon className="w-6 text-typography-primary" />
																			)
																		default:
																			return (
																				<ChargerDcIcon className="w-4 text-typography-primary" />
																			)
																	}
																} else {
																	return <ChargerAcIcon className="w-6 text-typography-primary" />
																}
															})()}
														</div>
														{/* Connector Details */}
														<div className="max-w-[calc(100%-42px)]">
															<p
																className="body-1-medium truncate text-left text-typography-primary"
																data-testid={`cils-card-${i}-btn-connector-${j}-standard`}
															>
																{connectorType}
															</p>
															<p className="body-1-normal truncate text-left text-typography-secondary">
																<span data-testid={`cils-card-${i}-btn-connector-${j}-power-type`}>
																	{power_type === OmniConnectorPowerType.Dc ? 'DC' : 'AC'}
																</span>{' '}
																<span data-testid={`cils-card-${i}-btn-connector-${j}-power`}>
																	{connectorPower}
																</span>
															</p>
														</div>
													</button>
												)
											})}
										</div>
									</div>
								)
							}
						)}
					</div>
					{/* Footer */}
					<PoweredByFooter className="mt-6" />
				</>
			) : (
				<Notice
					type="error"
					header={intl.formatMessage({
						id: 'Common.GenericErrorTitle',
						defaultMessage: 'Oops! Something Went Wrong'
					})}
					description={
						errorMessage
							? errorMessage
							: intl.formatMessage({
									id: 'Common.GenericErrorDescription',
									defaultMessage: 'Please try again later'
								})
					}
				/>
			)}
		</ScreenContainer>
	)
}

export default CheckInLocationScreen
