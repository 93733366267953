import { useQueryClient } from '@tanstack/react-query'
import { useCallback, useEffect } from 'react'

import { useAddPaymentMethodMutation } from '../mutations/payments'
import { ROOT_PAYMENTS_QUERY_KEY, useTngSetupIntentQuery } from '../queries/payments'
import { useMiniProgramContext } from './useMiniProgramContext'

/**
 * Handles the permission request and addition of the TNG Payment Method.
 */
const useAddTngPaymentMethod = (): {
	addTngPaymentMethod: () => void
	isAddTngPaymentMethodPending: boolean
	isTngSetupIntentLoading: boolean
} => {
	const queryClient = useQueryClient()

	const { miniProgram } = useMiniProgramContext()

	const { isPending: isAddTngPaymentMethodPending, mutateAsync: addPaymentMethod } =
		useAddPaymentMethodMutation()

	const { data: tngSetupIntentData, isLoading: isTngSetupIntentLoading } = useTngSetupIntentQuery({
		enabled: !!miniProgram
	})

	/**
	 * Request for permission in the Mini Program to add TNG as a payment method.
	 * 1. Open up the TNG Mini Program Drawer with the provided `authUrl`.
	 * 2. If the permission was granted and the `response` is valid, attempt to add the TNG Payment Method.
	 * 3. Invalidate Payment Method Query to trigger re-fetch of the list of Payment Methods.
	 * 4. Invalidate TNG Setup Intent Query to allow another Payment Method to be added.
	 */
	const addTngPaymentMethod = useCallback((): void => {
		// Handle missing Mini Program or `auth_url`.
		if (!miniProgram || !tngSetupIntentData?.auth_url) {
			console.debug(
				`[useAddTngPaymentMethod > miniProgram.call] Mini Program or Auth URL is not defined`,
				{
					miniProgram,
					tngSetupIntentData
				}
			)
			return
		}

		// Step 1
		miniProgram.call('tngdSignContract', { authUrl: tngSetupIntentData.auth_url }, (response) => {
			if ('authCode' in response) {
				// Step 2
				void addPaymentMethod(
					{
						type: 'tng',
						tng: {
							auth_code: typeof response.authCode === 'string' ? response.authCode : ''
						}
					},
					{
						onError: (response): void => {
							miniProgram.alert({
								title: 'Oops! Something Went Wrong',
								content: JSON.stringify(response)
							})
						},
						// Steps 3 and 4
						onSettled: (): void => {
							const invalidatePaymentMethodQueries = async (): Promise<void> => {
								// Invalidates all payments-related queries.
								await queryClient.invalidateQueries({
									queryKey: [ROOT_PAYMENTS_QUERY_KEY]
								})
							}
							void invalidatePaymentMethodQueries()
						}
					}
				)
			} else {
				console.debug(
					`[useAddTngPaymentMethod > miniProgram.call] Did not add TNG Payment Method due to missing authCode:`,
					{
						response,
						tngSetupIntentData
					}
				)
			}
		})
	}, [miniProgram, queryClient, tngSetupIntentData, addPaymentMethod])

	/**
	 * Setup Mini Program `onMessage` Callback
	 */
	useEffect((): (() => void) | undefined => {
		if (miniProgram) {
			miniProgram.onMessage = (messageDetail): void => {
				console.debug('[useAddTngPaymentMethod > miniProgram.onMessage]', messageDetail)
			}
			return (): void => {
				delete miniProgram.onMessage
			}
		}
	}, [miniProgram])

	return { addTngPaymentMethod, isAddTngPaymentMethodPending, isTngSetupIntentLoading }
}

export default useAddTngPaymentMethod
