import { useCallback } from 'react'
import Button from 'src/_shared/components/Button'
import InputOtp from 'src/_shared/components/InputOtp'
import Spinner from 'src/_shared/components/Spinner'
import { useUserOtpRequestMutation, useUserOtpVerifyMutation } from 'src/_shared/mutations/auth'

import AccountScreenHeader from '../../../_shared/components/AccountScreenHeader'
import useCountdownTimer from '../../../_shared/hooks/useCountdownTimer'
import { FormFieldKey } from '../enums'
import { AccountForgotPasswordScreenCommonViewProps } from '../types'

type EnterOtpViewProps = AccountForgotPasswordScreenCommonViewProps

const EnterOtpView = ({
	formValues,
	setFormValues,
	onNext: handleNext
}: EnterOtpViewProps): JSX.Element => {
	const countryCode = formValues[FormFieldKey.CountryCode]

	const mobileNumber = formValues[FormFieldKey.MobileNumber]

	const otp = formValues[FormFieldKey.Otp]

	const [seconds, resetCountdownTimer] = useCountdownTimer()

	const {
		error: otpResendError,
		isError: isOtpResendError,
		isPending: isRequestOtpPending,
		mutateAsync: requestOtp,
		reset: resetUserOtpRequestMutation
	} = useUserOtpRequestMutation()

	const {
		error: otpVerifyError,
		isError: isOtpVerifyError,
		isPending: isOtpVerifyPending,
		mutateAsync: verifyOtp,
		reset: resetUserOtpVerifyMutation
	} = useUserOtpVerifyMutation()

	const errorMessage =
		otpVerifyError?.response?.data.message ?? otpResendError?.response?.data.message

	const handleOtpChange = useCallback(
		(value: string): void => {
			setFormValues(
				(values): Record<FormFieldKey, string> => ({
					...values,
					[FormFieldKey.Otp]: value
				})
			)
			if (isOtpVerifyError && value !== otp) {
				resetUserOtpVerifyMutation()
			}
		},
		[isOtpVerifyError, otp, resetUserOtpVerifyMutation, setFormValues]
	)

	const handleRequestOtpClick = useCallback((): void => {
		void requestOtp(
			{
				mobileNumber: countryCode + mobileNumber
			},
			{
				onSuccess: (): void => {
					resetUserOtpRequestMutation()
					resetCountdownTimer()
				}
			}
		)
	}, [countryCode, mobileNumber, requestOtp, resetCountdownTimer, resetUserOtpRequestMutation])

	const handleOtpFilled = useCallback(
		(value: string): void => {
			void verifyOtp(
				{
					mobileNumber: countryCode + mobileNumber,
					otp: value
				},
				{
					onSuccess: (response): void => {
						setFormValues(
							(values): Record<FormFieldKey, string> => ({
								...values,
								[FormFieldKey.ResetToken]: response.data.token
							})
						)
						resetUserOtpVerifyMutation()
						handleNext?.()
					}
				}
			)
		},
		[countryCode, mobileNumber, handleNext, resetUserOtpVerifyMutation, setFormValues, verifyOtp]
	)

	return (
		<>
			<div className="flex flex-grow flex-col space-y-5">
				<AccountScreenHeader
					className="mb-[-4px]" // Account for margin in `subTitle`.
					title="Enter OTP"
					subTitle={
						<>
							We have sent a verification code to
							<span data-testid="afps-eov-text-mobile-no" className="body-1-semibold mt-1 block">
								{countryCode} {mobileNumber}
							</span>
						</>
					}
				/>
				<div className="flex flex-col items-center space-y-5">
					<InputOtp
						data-testid="afps-eov-input-otp"
						dataTestIdPrefix="afps-eov-input-otp"
						className="w-full"
						id={FormFieldKey.Otp}
						name={FormFieldKey.Otp}
						value={otp}
						disabled={isOtpVerifyPending}
						error={isOtpVerifyError || isOtpResendError}
						description={errorMessage}
						autoFocus={true}
						onChange={handleOtpChange}
						onFilled={handleOtpFilled}
					/>
					<p data-testid="afps-eov-text-remaining-seconds" className="body-1-normal text-center">
						{seconds} Sec
					</p>
					{isOtpVerifyPending && <Spinner className="h-5 w-5" />}
				</div>
			</div>
			<div className="flex flex-col items-center">
				<p
					data-testid="afps-eov-text-didnt-receive-otp"
					className="body-1-normal text-center text-typography-secondary"
				>
					Didn&apos;t receive the OTP code?
				</p>
				<Button
					data-testid="afps-eov-btn-resend-otp"
					variant="ghost"
					className="w-fit border-none"
					disabled={seconds > 0 || isOtpVerifyPending || isRequestOtpPending}
					onClick={handleRequestOtpClick}
					textProps={{
						className: 'uppercase body-1-semibold'
					}}
				>
					Resend New Code
				</Button>
			</div>
		</>
	)
}

export default EnterOtpView
