import { m as motion } from 'framer-motion'
import GoogleMap from 'google-maps-react-markers'
import { useCallback, useEffect, useMemo, useState } from 'react'
import IconCluster from 'src/_shared/_old/assets/svgs/IconCluster'
import { LAST_APPLIED_FILTERS_KEY } from 'src/_shared/_old/enums.ts'
import { OmniEVSE } from 'src/_shared/_old/schemas/evse'
import { Coordinates } from 'src/_shared/_old/schemas/geolocation'
import { CpoLocationPair, Filter, OmniLocation } from 'src/_shared/_old/schemas/typings'
import { handleCheckAvailability } from 'src/_shared/_old/utils/charging'
import { filterEvse } from 'src/_shared/_old/utils/filter.tsx'
import {
	formatDataToGeoJsonPoints,
	latLng2Point,
	MAP_TYPES,
	point2LatLng,
	miniPanTo
} from 'src/_shared/_old/utils/map'
import {
	MAP_DEFAULT_CENTER_LAT,
	MAP_DEFAULT_CENTER_LNG,
	GOOGLE_MAPS_API_KEY,
	GOOGLE_MAPS_MAP_ID
} from 'src/_shared/constants/env'
import { classNames } from 'src/_shared/utils/elements'
import useSupercluster from 'use-supercluster'

import CurrentLocationPin from './CurrentLocationPin'
import Marker from './Marker'
import SearchLocationPin from './SearchLocationPin'

interface Props {
	zoom: number
	center: Coordinates | null
	mapType: number
	centerOffset: number
	isCurrentCoordsAvailable: boolean
	currentCoords: Coordinates
	locations: OmniLocation[]
	cpoLocations: CpoLocationPair[]
	onClickMarker: (location: OmniLocation) => void
	onSetZoom: (zoom: number) => void
	mapRef: React.RefObject<google.maps.Map>
	updateMapRef: (map: google.maps.Map, maps: typeof google.maps) => void
	searchLocation: Coordinates | null
	onSetSearchLocation: (search: Coordinates) => void
}

const ClusterMarker = ({ children }: React.PropsWithChildren<{ lat: number; lng: number }>) =>
	children

const DEFAULT_MAP_CENTER: Coordinates = {
	lat: MAP_DEFAULT_CENTER_LAT,
	lng: MAP_DEFAULT_CENTER_LNG
}

const MAP_OPTIONS: google.maps.MapOptions = {
	gestureHandling: 'greedy',
	clickableIcons: false,
	disableDefaultUI: true,
	styles: [
		{
			featureType: 'poi.business',
			elementType: 'labels',
			stylers: [{ visibility: 'off' }]
		}
	],
	mapId: GOOGLE_MAPS_MAP_ID
}

// configure this to toggle the zoom level when a user clicks on the dropped pin
const DROPPED_PIN_ZOOM_LEVEL = 18

const Map = (props: Props) => {
	const {
		zoom,
		mapType,
		locations,
		center,
		centerOffset,
		isCurrentCoordsAvailable,
		currentCoords,
		onClickMarker,
		onSetZoom,
		mapRef,
		updateMapRef,
		searchLocation,
		onSetSearchLocation
	} = props

	const [bounds, setBounds] = useState<GeoJSON.BBox>([0, 0, 0, 0])

	const data = useMemo(() => {
		return formatDataToGeoJsonPoints(locations)
	}, [locations])

	const { clusters, supercluster } = useSupercluster({
		points: data,
		bounds,
		zoom,
		options: {
			radius: 150,
			maxZoom: 16
		}
	})

	useEffect(
		() => {
			if (mapRef.current?.getProjection()) {
				const map = mapRef.current
				const currentCenter = center?.lat && center.lng ? center : DEFAULT_MAP_CENTER

				// if not default position, dont add Y offset (height of bottom bar / locations details popup)
				const isDefaultPosition = !center?.lat && !center?.lng

				const centerPoint = latLng2Point(currentCenter, map, zoom)
				const offsetPoint = {
					x: centerPoint?.x ?? 0,
					y: isDefaultPosition ? centerPoint?.y ?? 0 : (centerPoint?.y ?? 0) + centerOffset
				}
				const offsetLatLng = {
					lat: point2LatLng(offsetPoint, map, zoom)?.lat() ?? 0,
					lng: point2LatLng(offsetPoint, map, zoom)?.lng() ?? 0
				}
				map.panTo(offsetLatLng)
			}
		},
		// Note: `zoom` shouldn't be a dependency here because it will cause
		// the map to be janky and keep resetting its position when zooming.
		// Not sure what this `useEffect` is trying to achieve.
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[mapRef, center, centerOffset]
	)

	useEffect(() => {
		if (mapRef.current) {
			mapRef.current.setZoom(zoom)
		}
	}, [mapRef, zoom])

	const handleMapChange = useCallback(
		(params: {
			bounds: google.maps.LatLngBounds
			center: (number | undefined)[]
			zoom: number
		}) => {
			const ne = params.bounds.getNorthEast()
			const sw = params.bounds.getSouthWest()
			/**
			 * useSupercluster accepts bounds in the form of [westLng, southLat, eastLng, northLat]
			 * const { clusters, supercluster } = useSupercluster({
			 *	points: points,
			 *	bounds: mapBounds.bounds,
			 *	zoom: mapBounds.zoom,
			 * })
			 */
			setBounds([sw.lng(), sw.lat(), ne.lng(), ne.lat()])
			onSetZoom(params.zoom)
		},
		[onSetZoom]
	)

	const handleClusterClick = (clusterId: number, lat: number, lng: number) => {
		const expansionZoom = Math.min(supercluster?.getClusterExpansionZoom(clusterId) ?? 0, 20)
		mapRef.current?.setZoom(expansionZoom)
		if (mapRef.current) {
			miniPanTo([], [], { lat, lng }, mapRef.current, 10, 5)
		}
	}

	const isClusterAvailable = (clusterId: number) => {
		const points = supercluster?.getLeaves(clusterId, Infinity)

		const lastAppliedFilters = JSON.parse(
			sessionStorage.getItem(LAST_APPLIED_FILTERS_KEY) ?? 'null'
		) as Filter | null

		const lastAppliedPowerTypeFilter = lastAppliedFilters?.powerTypeFilter ?? null

		const hasAvailable = points?.some((point) => {
			const location = (point.properties?.location || {}) as OmniLocation
			return filterEvse(location.evses, lastAppliedPowerTypeFilter).some((evse: OmniEVSE) =>
				handleCheckAvailability(evse)
			)
		})
		return hasAvailable
	}

	const centerToDroppedPin = (searchLocation: Coordinates) => {
		onSetZoom(DROPPED_PIN_ZOOM_LEVEL)
		onSetSearchLocation(searchLocation)
	}

	return (
		<GoogleMap
			apiKey={GOOGLE_MAPS_API_KEY}
			defaultCenter={isCurrentCoordsAvailable ? currentCoords : DEFAULT_MAP_CENTER}
			defaultZoom={isCurrentCoordsAvailable ? 15 : 12}
			options={{
				...MAP_OPTIONS,
				mapTypeId: MAP_TYPES[mapType]
			}}
			onGoogleApiLoaded={({ map, maps }) => {
				updateMapRef(map, maps)
			}}
			onChange={handleMapChange}
		>
			{clusters.map((cluster) => {
				const [longitude, latitude] = cluster.geometry.coordinates
				const {
					cluster: isCluster,
					point_count: pointCount,
					location
				} = (cluster.properties ?? {}) as {
					cluster: boolean
					point_count: string
					location: OmniLocation
				}
				if (isCluster) {
					return (
						<ClusterMarker key={`cluster-${cluster.id}`} lat={latitude} lng={longitude}>
							<motion.div
								data-testid={
									isClusterAvailable(Number(cluster.id))
										? 'cluster-marker-avail'
										: 'cluster-marker-unavail'
								}
								onClick={() => {
									handleClusterClick(Number(cluster.id), latitude, longitude)
								}}
								// For some reason, the google-maps-react-markers library does not work
								// well with framer on Safari when only changing opacity.
								// Adding the scale from 0.99 to 1 has no visual difference, but makes the
								// animation much more consistent.
								// I have zero clue why this works...
								initial={{ opacity: 0, scale: 0.99 }}
								animate={{ opacity: 1, scale: 1 }}
								exit={{ opacity: 0 }}
								transition={{
									ease: 'easeInOut',
									duration: 0.5
								}}
							>
								<IconCluster
									className={classNames(
										'h-20 w-20',
										isClusterAvailable(Number(cluster.id)) ? 'text-success-400' : 'text-error-300'
									)}
								/>
								<div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform text-[16px] text-white">
									{pointCount}
								</div>
							</motion.div>
						</ClusterMarker>
					)
				}
				return (
					<Marker
						key={`marker-${location.uid}`}
						hide={false}
						lat={latitude}
						lng={longitude}
						location={location}
						onClick={onClickMarker}
					/>
				)
			})}
			{searchLocation && (
				<SearchLocationPin
					lat={searchLocation.lat}
					lng={searchLocation.lng}
					toZoomIn={centerToDroppedPin}
				/>
			)}
			{isCurrentCoordsAvailable && (
				<CurrentLocationPin lat={currentCoords.lat} lng={currentCoords.lng} />
			)}
		</GoogleMap>
	)
}

export default Map
