import { Navigate } from 'react-router'
import { ScreenRoutePath } from 'src/App/router/hooks'
import { BRAND } from 'src/_shared/constants/env'
import { Brand } from 'src/_shared/enums/env'

import KinetaLandingView from './components/KinetaLandingView'

const LandingScreen = (): JSX.Element => {
	switch (BRAND) {
		case Brand.Kineta:
			return <KinetaLandingView />
		default:
			return <Navigate to={ScreenRoutePath.Root} />
	}
}

export default LandingScreen
