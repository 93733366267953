import { useEffect } from 'react'
import Spinner from 'src/_shared/components/Spinner'
import { BRAND } from 'src/_shared/constants/env'
import { Brand } from 'src/_shared/enums/env'
import { delay } from 'src/_shared/utils/time'

import { ChargerScreenViewItemKey } from '../enums'
import useChargerDebugLogging from '../hooks/useChargerDebugLogging'
import { useChargerDetails } from '../hooks/useChargerDetails'
import { useChargerSessionDetails } from '../hooks/useChargerSessionDetails'
import { ChargerScreenCommonViewProps } from '../types'

type EndRedirectViewProps = ChargerScreenCommonViewProps

/**
 * Alternative branching flow for brands that do not require the `ReceiptView`
 * and would want a redirect back to their application or website.
 */
const EndRedirectView = ({
	routeParams,
	onNext: handleNext
}: EndRedirectViewProps): JSX.Element => {
	const { connector } = useChargerDetails(routeParams)

	const { session, isUserChargingSession } = useChargerSessionDetails(routeParams)

	/**
	 * Redirect to URL at the end of a charging session.
	 * FUTURE TODO: Replace with URL returned from `session`.
	 */
	useEffect((): void => {
		if (session?.uid) {
			const endRedirectUrl = ((): string | null => {
				switch (BRAND) {
					case Brand.Evme:
						return `https://evme.io/mobile/ev-stations/charging/complete?session=${session.uid}`
					default:
						return null
				}
			})()
			if (endRedirectUrl) {
				const redirectAfterDelay = async (): Promise<void> => {
					const startTime = Date.now()
					console.debug(
						`[${ChargerScreenViewItemKey.EndRedirectView}] Redirect Delay Start:`,
						startTime
					)
					await delay(5000)
					const endTime = Date.now()
					const duration = (endTime - startTime) / 1000
					console.debug(
						`[${ChargerScreenViewItemKey.EndRedirectView}] Redirect Delay End: ${endTime} (${duration} seconds)`
					)
					window.location.href = endRedirectUrl
				}

				switch (BRAND) {
					// Note: Hacky fix for EVme as they are not able to resolve
					// an issue on their end where session goes to complete too quickly
					// for them.
					// FUTURE TODO: remove when the issue is fixed on their end.
					case Brand.Evme:
						void redirectAfterDelay()
						break
					default:
						window.location.href = endRedirectUrl
				}
			} else {
				// Fallback to Receipt View
				handleNext?.()
			}
		}
	}, [session?.uid, handleNext])

	useChargerDebugLogging({
		connector,
		isUserChargingSession,
		session,
		viewItemKey: ChargerScreenViewItemKey.EndRedirectView
	})

	return (
		<div className="flex flex-grow flex-col items-center justify-center pb-6">
			<Spinner />
		</div>
	)
}

export default EndRedirectView
