import { OmniConnectorStandard } from 'src/_shared/enums/omni'

/**
 * Formats the provided Connector `standard` into a more user-friendly text.
 * @param {OmniConnectorStandard} standard The socket or plug standard of the connector.
 * @returns {string} The formatted text based on the provided `standard`.
 */
export const formatConnectorType = (standard?: OmniConnectorStandard): string => {
	if (standard) {
		switch (standard) {
			// Common EV Charging Connector Types
			case OmniConnectorStandard.Chademo:
				return 'CHAdeMO'
			case OmniConnectorStandard.Iec62196T1:
				return 'Type 1'
			case OmniConnectorStandard.Iec62196T1Combo:
				return 'CCS1'
			case OmniConnectorStandard.Iec62196T2:
				return 'Type 2'
			case OmniConnectorStandard.Iec62196T2Combo:
				return 'CCS2'
			case OmniConnectorStandard.Mcs:
				return standard // MCS
			case OmniConnectorStandard.SaeJ3400:
				return 'SAE J3400'
			case OmniConnectorStandard.TeslaS:
				return 'Model-S'
			default:
				return standard
		}
	}
	return '-'
}
