import { ScreenRoutePath, useRouterNavigate } from 'src/App/router/hooks'
import ScreenContainer from 'src/_shared/components/ScreenContainer'
import AccountFilledIcon from 'src/_shared/components/_icons/AccountFilledIcon'
import CardIcon from 'src/_shared/components/_icons/CardIcon'
import CheckCircleIcon from 'src/_shared/components/_icons/CheckCircleIcon'
import ChevronRightIcon from 'src/_shared/components/_icons/ChevronRightIcon'
import LogoutIcon from 'src/_shared/components/_icons/LogoutIcon'
import ProfileIcon from 'src/_shared/components/_icons/ProfileIcon'
import QuestionMarkCircledIcon from 'src/_shared/components/_icons/QuestionMarkCircledIcon'
import ShieldSecurityIcon from 'src/_shared/components/_icons/ShieldSecurityIcon'
import { APP_MODE, APP_VERSION } from 'src/_shared/constants/env'
import { AppMode } from 'src/_shared/enums/env'
import { useAuthContext } from 'src/_shared/hooks/useAuthContext'

import { MenuItem } from './types'

const ACCOUNT_MENU_ITEMS: MenuItem[] = [
	{
		title: 'My Profile',
		icon: <ProfileIcon className="h-6 w-6" />,
		route: ScreenRoutePath.AccountProfile,
		'data-testid': 'as-btn-my-profile'
	},
	{
		title: 'My Payment Methods',
		icon: <CardIcon className="h-5 w-5" />,
		route: ScreenRoutePath.AccountPaymentMethods,
		'data-testid': 'as-btn-my-payment-methods'
	},
	{
		title: 'Help',
		icon: <QuestionMarkCircledIcon className="h-5 w-5" />,
		route: ScreenRoutePath.AccountHelp,
		'data-testid': 'as-btn-help'
	},
	{
		title: 'Terms & Conditions',
		icon: <ShieldSecurityIcon className="h-6 w-6" />,
		route: ScreenRoutePath.TermsAndConditions,
		'data-testid': 'as-btn-terms-and-conditions'
	}
]

const AccountScreen = (): JSX.Element => {
	const navigate = useRouterNavigate()

	const { user, clearAuthTokens } = useAuthContext()

	const isAppModeSso = APP_MODE === AppMode.Sso

	const handleMenuItemClick = (pathname: ScreenRoutePath) => (): void => {
		navigate(pathname)
	}

	const handleLogoutClick = (): void => {
		clearAuthTokens()
		navigate(ScreenRoutePath.Map)
	}

	return (
		<ScreenContainer hideTopBar>
			<p
				data-testid="as-text-app-verison"
				className="body-1-normal flex w-full items-center justify-end bg-primary-800 pr-5 pt-4 text-white"
			>
				App Version {APP_VERSION}
			</p>
			{/* Account Details */}
			<div className="flex h-fit w-full flex-col items-center justify-center bg-primary-800 py-10">
				<div className="flex w-full flex-col items-center justify-center space-y-2">
					<div className="rounded-full bg-white bg-opacity-80 p-2">
						<AccountFilledIcon className="h-24 w-auto text-primary-800" />
					</div>
					<div className="flex flex-col items-center justify-center">
						<p data-testid="as-text-username" className="body-3-semibold text-white">
							{user?.name ? user.name : '-'}
						</p>
						<p data-testid="as-text-email" className="body-2-normal text-white">
							{user?.email ? user.email : '-'}
						</p>
					</div>
					<div className="flex flex-row items-center justify-center space-x-1 rounded-full bg-grayscale-800 py-1 pl-3 pr-1">
						<p className="body-1-normal text-white">Verified</p>
						<CheckCircleIcon className="h-5 w-5 text-white" />
					</div>
				</div>
			</div>
			{/* Menu */}
			<div className="mt-5 flex w-full flex-col bg-white px-5 py-2 pb-20">
				{/* Account */}
				<p className="body-3-semibold text-typography-primary">Account</p>
				<div className="flex flex-col pt-2">
					{/* Menu Items */}
					{ACCOUNT_MENU_ITEMS.map(
						({ title, icon, route, 'data-testid': dataTestId }, index): JSX.Element => {
							return (
								<button
									data-testid={dataTestId}
									key={index}
									className="flex w-full flex-row items-center justify-between border-divider-primary py-5 text-typography-primary [&:not(:last-child)]:border-b"
									onClick={handleMenuItemClick(route)}
								>
									<div className="flex flex-row items-center space-x-4">
										<div className="flex w-6 items-center justify-center">{icon}</div>
										<div className="body-3-normal">{title}</div>
									</div>
									<ChevronRightIcon className="h-6 w-6" />
								</button>
							)
						}
					)}
					{/* Logout */}
					{!isAppModeSso && (
						<button
							data-testid="as-btn-logout"
							className="justify start flex w-full flex-row items-center py-5"
							onClick={handleLogoutClick}
						>
							<div className="flex flex-row items-center space-x-4">
								<div className="flex w-6 items-center justify-center">
									<LogoutIcon className="h-6 w-6 text-error-300" />
								</div>
								<div className="body-3-medium text-error-300">Sign Out</div>
							</div>
						</button>
					)}
				</div>
			</div>
		</ScreenContainer>
	)
}

export default AccountScreen
