import { memo } from 'react'
import { useIntl } from 'react-intl'
import { useRouteError } from 'react-router-dom'
import Notice from 'src/_shared/components/Notice'
import ScreenContainer from 'src/_shared/components/ScreenContainer'

const ErrorElement = (): JSX.Element => {
	const intl = useIntl()
	const error = useRouteError()
	return (
		<ScreenContainer hideBottomBar>
			<Notice
				type="error"
				header={intl.formatMessage({
					id: 'Common.GenericErrorTitle',
					defaultMessage: 'Oops! Something Went Wrong'
				})}
				description={
					error
						? String(error)
						: intl.formatMessage({
								id: 'Common.GenericErrorDescription',
								defaultMessage: 'Please try again later'
							})
				}
			/>
		</ScreenContainer>
	)
}

const MemoisedErrorElement = memo(ErrorElement)

export default MemoisedErrorElement
