import { PropsWithChildren, useCallback } from 'react'
import { StrapiContext } from 'src/_shared/hooks/useStrapiContext'
import { StrapiFeatureToggles, useStrapiBrandQuery } from 'src/_shared/queries/strapi'

/**
 * Retrieves Brand-specific data from Strapi that will be used in the application.
 */
const StrapiBrandWrapper = ({ children }: PropsWithChildren): JSX.Element | null => {
	const { data: brandData = null, isLoading: isStrapiBrandLoading } = useStrapiBrandQuery({
		staleTime: Infinity
	})

	const getFeatureToggles = useCallback(
		<T extends keyof StrapiFeatureToggles>(
			screen?: T
		): StrapiFeatureToggles | StrapiFeatureToggles[T] | null => {
			const featureToggles = brandData?.attributes.configuration?.featureToggles
			if (featureToggles) {
				if (screen) {
					const featureTogglesByScreen = featureToggles[screen]
					if (featureTogglesByScreen) {
						console.debug(
							`[StrapiBrandWrapper] "${screen}" Feature Toggles:`,
							featureTogglesByScreen
						)
						return featureTogglesByScreen
					}
					console.debug(`[StrapiBrandWrapper] No "${screen}" Feature Toggles Data Found`)
					return null
				}
				console.debug(`[StrapiBrandWrapper] Feature Toggles:`, featureToggles)
				return featureToggles
			}
			console.debug('[StrapiBrandWrapper] No Feature Toggles Data Found')
			return null
		},
		[brandData]
	)

	if (isStrapiBrandLoading) {
		return null
	}
	return (
		<StrapiContext.Provider value={{ brandData, getFeatureToggles }}>
			{children}
		</StrapiContext.Provider>
	)
}

export default StrapiBrandWrapper
