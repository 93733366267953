import { useMemo } from 'react'

import { useLocationsQuery } from '../queries/locations'
import { useStrapiContext } from './useStrapiContext'

export interface CpoData {
	name: string
	entityCode: string
	imageUrl: string
	disableScanQrButton?: boolean
}

const useCheckInCpos = (): { cpos: CpoData[]; isCpoDataLoading: boolean } => {
	const { brandData } = useStrapiContext()

	const strapiCpos = brandData?.attributes.configuration?.checkInCpoOptions

	const { data: cposFromLocations = [], isLoading: isCpoDataLoading } = useLocationsQuery<
		CpoData[]
	>(
		{ fetchAll: true },
		{
			enabled: !strapiCpos || strapiCpos.length === 0,
			staleTime: Infinity,
			/**
			 * Returns the CPOs that have at least 1 connector with a QR identifier.
			 */
			select: (locations): CpoData[] => {
				const cposMap = locations.reduce<Partial<Record<string, CpoData>>>(
					(
						map,
						{ entity_code: entityCode = '', operator = { name: '', logo: '' }, evses = [] }
					): Partial<Record<string, CpoData>> => {
						const hasConnectorWithQrIdentifier = evses.some(({ connectors = [] }): boolean => {
							return connectors.some((connector): boolean => {
								return !!connector.qr_identifier
							})
						})
						if (
							!map[entityCode] &&
							operator.name &&
							operator.logo &&
							entityCode &&
							hasConnectorWithQrIdentifier
						) {
							return {
								...map,
								[entityCode]: {
									name: operator.name,
									entityCode,
									imageUrl: operator.logo
								}
							}
						}
						return map
					},
					{}
				)
				const cpoData = Object.values(cposMap) as CpoData[]
				return cpoData.sort((a, b): number => {
					return a.name.localeCompare(b.name)
				})
			}
		}
	)

	/**
	 * List of CPOs from Strapi (if provided) or Locations API (as fallback).
	 */
	const cpos = useMemo((): CpoData[] => {
		return strapiCpos?.length ? (strapiCpos as CpoData[]) : cposFromLocations
	}, [cposFromLocations, strapiCpos])

	return { cpos, isCpoDataLoading: strapiCpos?.length ? false : isCpoDataLoading }
}

export default useCheckInCpos
