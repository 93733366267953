import { DetailedHTMLProps, ImgHTMLAttributes, memo } from 'react'

import visaLogo from './visaLogo.png'

const VisaLogoImage = ({
	alt = 'Visa',
	...props
}: Omit<
	DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>,
	'src'
>): JSX.Element => {
	return <img alt={alt} src={visaLogo} {...props} />
}

const MemoisedVisaLogoImage = memo(VisaLogoImage)

export default MemoisedVisaLogoImage
