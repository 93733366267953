import { DetailedHTMLProps, ImgHTMLAttributes, memo } from 'react'

import googlePlayBadge from './googlePlayBadge.png'

const GooglePlayBadgeImage = ({
	alt = 'Get it on Google Play',
	...props
}: Omit<
	DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>,
	'src'
>): JSX.Element => {
	return <img alt={alt} src={googlePlayBadge} {...props} />
}

const MemoisedGooglePlayBadgeImage = memo(GooglePlayBadgeImage)

export default MemoisedGooglePlayBadgeImage
