import { DetailedHTMLProps, ImgHTMLAttributes, memo } from 'react'

import unionPayLogo from './unionPayLogo.png'

const UnionPayLogoImage = ({
	alt = 'UnionPay',
	...props
}: Omit<
	DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>,
	'src'
>): JSX.Element => {
	return <img alt={alt} src={unionPayLogo} {...props} />
}

const MemoisedUnionPayLogoImage = memo(UnionPayLogoImage)

export default MemoisedUnionPayLogoImage
