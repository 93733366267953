import { ChangeEvent, FormEvent, Fragment, useCallback, useEffect, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { ScreenRoutePath } from 'src/App/router/hooks'
import AccountScreenHeader from 'src/_shared/components/AccountScreenHeader'
import Button from 'src/_shared/components/Button'
import Checkbox from 'src/_shared/components/Checkbox'
import Input from 'src/_shared/components/Input'
import InputPassword from 'src/_shared/components/InputPassword'
import SelectCountry from 'src/_shared/components/SelectCountry'
import CheckIcon from 'src/_shared/components/_icons/CheckIcon'
import EmailIcon from 'src/_shared/components/_icons/EmailIcon'
import LicensePlateIcon from 'src/_shared/components/_icons/LicensePlateIcon'
import ProfileIcon from 'src/_shared/components/_icons/ProfileIcon'
import TelephoneIcon from 'src/_shared/components/_icons/TelephoneIcon'
import UnlockIcon from 'src/_shared/components/_icons/UnlockIcon'
import { PASSWORD_REQUIREMENTS_TEXT } from 'src/_shared/constants/account'
import { BRAND } from 'src/_shared/constants/env'
import { Brand } from 'src/_shared/enums/env'
import {
	useUserRegistrationEligibilityMutation,
	useUserValidateEmailMutation
} from 'src/_shared/mutations/auth'
import { arePasswordsMatching, isPasswordComplexityMet } from 'src/_shared/utils/account'
import { formatDataTestId } from 'src/_shared/utils/string'
import isEmail from 'validator/lib/isEmail'
import isMobilePhone from 'validator/lib/isMobilePhone'

import { FormFieldKey } from '../enums'
import { AccountRegistrationScreenCommonViewProps, FormField } from '../types'

type RegistrationViewProps = AccountRegistrationScreenCommonViewProps

const DEBOUNCE_CHECK_DELAY = 375

const RegistrationView = ({
	formValues,
	setFormValues,
	setShouldSendOtp,
	onNext: handleNext
}: RegistrationViewProps): JSX.Element => {
	const formattedMobileNumber =
		formValues[FormFieldKey.CountryCode] + formValues[FormFieldKey.MobileNumber]

	const email = formValues[FormFieldKey.Email]

	const {
		error: userRegistrationEligibilityError,
		isError: isUserRegistrationEligibilityError,
		isSuccess: isUserRegistrationEligibilitySuccess,
		mutateAsync: checkUserRegistrationEligibility,
		reset: resetUserRegistrationEligibilityMutation
	} = useUserRegistrationEligibilityMutation()

	const {
		error: validateEmailError,
		isError: isValidateEmailError,
		isSuccess: isValidateEmailSuccess,
		mutateAsync: validateEmail,
		reset: resetUserValidateEmailMutation
	} = useUserValidateEmailMutation()

	const formFields = useMemo((): FormField[] => {
		const password = formValues[FormFieldKey.Password]

		const confirmPassword = formValues[FormFieldKey.ConfirmPassword]

		const mobileNumberFormField: FormField = {
			key: FormFieldKey.MobileNumber,
			label: 'Mobile Number',
			icon: <TelephoneIcon className="h-5 w-5 text-typography-secondary" />,
			errorMessage: ((): string | undefined => {
				if (!!formValues[FormFieldKey.MobileNumber] && !isMobilePhone(formattedMobileNumber)) {
					return `${formattedMobileNumber} is not a valid mobile number`
				}
				// Handle 4XX/5XX status errors thrown with messages.
				if (isUserRegistrationEligibilityError) {
					const { message: errorMessage, status: statusCode } =
						userRegistrationEligibilityError.response?.data ?? {}
					switch (true) {
						case statusCode === 403:
							return 'Mobile number has not been whitelisted by fleet'
						case statusCode === 409:
							return 'Mobile number has been registered before'
						// Fallback to error message if the status codes above are not encountered.
						case !!errorMessage:
							return errorMessage
						default:
							return 'Oops! Something went wrong'
					}
				}
			})()
		}

		const nameFormField: FormField = {
			key: FormFieldKey.Name,
			label: 'Name',
			icon: <ProfileIcon className="h-5 w-5 text-typography-secondary" />
		}

		const passwordFormField: FormField = {
			key: FormFieldKey.Password,
			label: 'Password',
			icon: <UnlockIcon className="h-5 w-5 text-typography-secondary" />,
			errorMessage: ((): string | undefined => {
				if (password.length > 0 && !isPasswordComplexityMet(password)) {
					return 'Your password does not meet the complexity requirements'
				}
			})()
		}

		const confirmPasswordFormField: FormField = {
			key: FormFieldKey.ConfirmPassword,
			label: 'Confirm Password',
			icon: <UnlockIcon className="h-5 w-5 text-typography-secondary" />,
			errorMessage: ((): string | undefined => {
				if (!arePasswordsMatching(password, confirmPassword)) {
					return 'Your password does not match'
				}
			})()
		}

		const emailFormField: FormField = {
			key: FormFieldKey.Email,
			label: 'Email',
			icon: <EmailIcon className="h-5 w-5 text-typography-secondary" />,
			errorMessage: ((): string | undefined => {
				if (!!email && !isEmail(email)) {
					return `${email} is not a valid email format`
				}
				if (isValidateEmailError) {
					const { message: errorMessage, status: statusCode } =
						validateEmailError.response?.data ?? {}
					switch (true) {
						case statusCode === 409:
							return 'Email address is already in use'
						// Fallback to error message if the status codes above are not encountered.
						case !!errorMessage:
							return errorMessage
						default:
							return 'Oops! Something went wrong'
					}
				}
			})()
		}

		const licensePlateNumberFormField: FormField = {
			key: FormFieldKey.LicensePlateNumber,
			label: 'License Plate Number (Optional)',
			icon: <LicensePlateIcon className="h-5 w-5 text-typography-secondary" />
		}

		return [
			mobileNumberFormField,
			nameFormField,
			passwordFormField,
			confirmPasswordFormField,
			emailFormField,
			licensePlateNumberFormField
		]
	}, [
		email,
		formValues,
		formattedMobileNumber,
		isUserRegistrationEligibilityError,
		isValidateEmailError,
		userRegistrationEligibilityError,
		validateEmailError
	])

	const isFormValid = useMemo((): boolean => {
		const isNameFilledIn = !!formValues[FormFieldKey.Name].trim()
		const hasNoErrorMessages = formFields.every(({ errorMessage }): boolean => {
			return !errorMessage
		})
		const acceptedTermsAndConditions = formValues[FormFieldKey.AcceptTermsAndConditions] === 'true'
		return (
			isNameFilledIn &&
			hasNoErrorMessages &&
			acceptedTermsAndConditions &&
			isUserRegistrationEligibilitySuccess &&
			isValidateEmailSuccess
		)
	}, [formFields, formValues, isUserRegistrationEligibilitySuccess, isValidateEmailSuccess])

	const handleFormChange = useCallback(
		(key: FormFieldKey) =>
			(event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>): void => {
				const formattedValue = ((): string => {
					const formValue = event.currentTarget.value
					switch (key) {
						case FormFieldKey.MobileNumber:
							return formValue.replace(/[^0-9]/, '')
						case FormFieldKey.Name:
						case FormFieldKey.LicensePlateNumber:
							return formValue.replace(/\s{2,}/g, ' ')
						default:
							return formValue.replace(/\s/g, '')
					}
				})()
				setFormValues(
					(values: Record<FormFieldKey, string>): Record<FormFieldKey, string> => ({
						...values,
						[key]: formattedValue
					})
				)
				// Clears mobile number registration eligibility error.
				if (
					[FormFieldKey.CountryCode, FormFieldKey.MobileNumber].includes(key) &&
					isUserRegistrationEligibilityError
				) {
					resetUserRegistrationEligibilityMutation()
				}
				// Clears email validation error/success.
				else if (key === FormFieldKey.Email) {
					resetUserValidateEmailMutation()
				}
			},
		[
			isUserRegistrationEligibilityError,
			resetUserRegistrationEligibilityMutation,
			resetUserValidateEmailMutation,
			setFormValues
		]
	)

	const handleAcceptTermsAndConditionsChange = useCallback(
		(checked: boolean): void => {
			setFormValues(
				(values): Record<FormFieldKey, string> => ({
					...values,
					[FormFieldKey.AcceptTermsAndConditions]: String(checked)
				})
			)
		},
		[setFormValues]
	)

	const handleRegisterClick = useCallback(
		(event: FormEvent<HTMLFormElement>): void => {
			// Prevent default form submission behaviour.
			event.preventDefault()

			if (isFormValid) {
				// tells the EnterOtpScreen to send OTP immediately
				setShouldSendOtp(true)
				handleNext?.()
			}
		},
		[isFormValid, handleNext, setShouldSendOtp]
	)

	/**
	 * Mobile Phone Eligibility Check
	 */
	useEffect((): (() => void) | undefined => {
		if (isMobilePhone(formattedMobileNumber)) {
			const timeoutId = setTimeout((): void => {
				void checkUserRegistrationEligibility({
					mobileNumber: formattedMobileNumber
				})
			}, DEBOUNCE_CHECK_DELAY)
			return (): void => {
				clearTimeout(timeoutId)
			}
		}
	}, [formattedMobileNumber, checkUserRegistrationEligibility])

	/**
	 * Email Validity Check
	 */
	useEffect((): (() => void) | undefined => {
		if (isEmail(email)) {
			const timeoutId = setTimeout((): void => {
				void validateEmail({
					email
				})
			}, DEBOUNCE_CHECK_DELAY)
			return (): void => {
				clearTimeout(timeoutId)
			}
		}
	}, [email, validateEmail])

	return (
		<form
			data-testid="ars-rv-form-register"
			className="flex flex-grow flex-col"
			onSubmit={handleRegisterClick}
		>
			<div className="flex flex-grow flex-col space-y-5">
				<AccountScreenHeader
					className="mb-2"
					title={((): JSX.Element | string => {
						switch (BRAND) {
							case Brand.Grab:
								return (
									<>
										EV Charging Pilot
										<br />
										Sign Up
									</>
								)
							default:
								return 'Sign Up'
						}
					})()}
					subTitle="Please enter your details to sign up."
				/>
				<div className="flex flex-grow flex-col">
					{formFields.map(({ key, label, errorMessage, icon }, index): JSX.Element => {
						const commonProps = {
							key: index,
							id: key,
							name: key,
							value: formValues[key],
							placeholder: label,
							className: index < formFields.length - 1 ? 'mb-3' : 'mb-5',
							autoComplete: 'off',
							description: errorMessage,
							error: !!errorMessage,
							startAdornment: <div className="pl-1">{icon}</div>,
							onChange: handleFormChange(key),
							dataTestIdPrefix: formatDataTestId(['ars-rv-input', key])
						}

						switch (key) {
							case FormFieldKey.MobileNumber: {
								return (
									<Input
										{...commonProps}
										type="tel"
										startAdornment={
											<div className="flex flex-row items-center space-x-3 py-1 pl-1">
												{icon}
												<SelectCountry
													dataTestIdPrefix="ars-rv"
													id={FormFieldKey.CountryCode}
													name={FormFieldKey.CountryCode}
													value={formValues[FormFieldKey.CountryCode]}
													onChange={handleFormChange(FormFieldKey.CountryCode)}
												/>
											</div>
										}
										endAdornment={
											isUserRegistrationEligibilitySuccess && (
												<div className="p-1">
													<CheckIcon className="h-6 w-6 text-typography-primary" />
												</div>
											)
										}
									/>
								)
							}
							case FormFieldKey.Password: {
								return (
									<Fragment key={commonProps.key}>
										<InputPassword {...commonProps} />
										<p className="caption-3-medium mb-3 text-typography-secondary">
											{PASSWORD_REQUIREMENTS_TEXT}
										</p>
									</Fragment>
								)
							}
							case FormFieldKey.ConfirmPassword: {
								return <InputPassword {...commonProps} />
							}
							case FormFieldKey.Email: {
								return (
									<Input
										{...commonProps}
										endAdornment={
											isValidateEmailSuccess && (
												<div className="p-1">
													<CheckIcon className="h-6 w-6 text-typography-primary" />
												</div>
											)
										}
									/>
								)
							}
							default: {
								return <Input {...commonProps} />
							}
						}
					})}
					<div>
						<Checkbox
							dataTestIdPrefix="ars-rv-terms-and-conditions"
							label={
								<>
									I have read and agree to the{' '}
									<Link to={ScreenRoutePath.TermsAndConditions} target="_blank">
										Terms & Conditions
									</Link>
								</>
							}
							id={FormFieldKey.AcceptTermsAndConditions}
							name={FormFieldKey.AcceptTermsAndConditions}
							checked={formValues[FormFieldKey.AcceptTermsAndConditions] === 'true'}
							onChange={handleAcceptTermsAndConditionsChange}
						/>
					</div>
				</div>
			</div>
			<div className="flex flex-col space-y-6 bg-gradient-to-b from-[#FFFFFFEE] to-white py-6">
				<Button data-testid="ars-rv-btn-signup" variant="primary" disabled={!isFormValid}>
					Sign Up
				</Button>
				<p className="body-1-normal text-center text-typography-secondary ">
					Already have an account?{' '}
					<Link
						data-testid="ars-rv-link-login"
						className="body-1-semibold text-typography-primary"
						to={ScreenRoutePath.AccountLogin}
					>
						Login
					</Link>
				</p>
			</div>
		</form>
	)
}

export default RegistrationView
