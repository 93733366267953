import { ChangeEvent, useCallback, useMemo, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useQueryParams } from 'src/App/router/hooks'
import Input from 'src/_shared/components/Input'
import PoweredByFooter from 'src/_shared/components/PoweredByFooter'
import isEmail from 'validator/lib/isEmail'

import { ChargerScreenViewItemKey } from '../enums'
import useChargerDebugLogging from '../hooks/useChargerDebugLogging'
import { useChargerDetails } from '../hooks/useChargerDetails'
import { useChargerSessionDetails } from '../hooks/useChargerSessionDetails'
import { ChargerScreenCommonViewProps, ChargerScreenQueryParams } from '../types'
import Footer from './Footer'

type TransientEmailViewProps = ChargerScreenCommonViewProps

const TransientEmailView = ({
	routeParams,
	onNext: handleNext
}: TransientEmailViewProps): JSX.Element => {
	const [{ email = '' }, setQueryParams] = useQueryParams<ChargerScreenQueryParams>()

	const [inputEmail, setInputEmail] = useState<string>(email)

	const intl = useIntl()

	const { connector } = useChargerDetails(routeParams)

	const { session, isUserChargingSession } = useChargerSessionDetails(routeParams)

	const isInputEmailValid = useMemo((): boolean => {
		return isEmail(inputEmail)
	}, [inputEmail])

	const handleEmailChange = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
		const value = event.target.value.replace(/\s/g, '')
		setInputEmail(value)
	}, [])

	const handleNextClick = useCallback((): void => {
		if (isInputEmailValid) {
			setQueryParams(
				{
					email: inputEmail
				},
				{
					replace: true
				}
			)
			handleNext?.()
		}
	}, [isInputEmailValid, inputEmail, handleNext, setQueryParams])

	useChargerDebugLogging({
		connector,
		isUserChargingSession,
		session,
		viewItemKey: ChargerScreenViewItemKey.TransientEmailView
	})

	return (
		<>
			<div className="flex-grow">
				<p className="body-3-normal mb-7 text-center text-typography-primary">
					<FormattedMessage
						id="TransientEmailView.DescriptionEnterEmail"
						defaultMessage="Please enter your email address to receive charging session updates."
					/>
				</p>
				<form data-testid="cs-tev-form-email" onSubmit={handleNextClick}>
					<Input
						dataTestIdPrefix="cs-tev-input-email"
						autoComplete="off"
						className="group"
						placeholder={intl.formatMessage({
							id: 'TransientEmailView.InputPlaceholderEnterEmail',
							defaultMessage: 'Enter your email here'
						})}
						name="email"
						value={inputEmail}
						onChange={handleEmailChange}
						disabled={false}
						error={!!inputEmail && !isInputEmailValid}
						description={
							!!inputEmail && !isInputEmailValid
								? intl.formatMessage(
										{
											id: 'TransientEmailView.InputErrorSuffixInvalidEmail',
											defaultMessage: '{email} is not a valid email format'
										},
										{
											email: inputEmail
										}
									)
								: null
						}
					/>
				</form>
			</div>
			{/* Footer */}
			<Footer
				className="space-y-6"
				buttonProps={{
					children: intl.formatMessage({
						id: 'TransientEmailView.ButtonTextNext',
						defaultMessage: 'Next'
					}),
					className: 'w-full',
					disabled: !isInputEmailValid,
					onClick: handleNextClick
				}}
			/>
			<PoweredByFooter className="pb-6" />
		</>
	)
}

export default TransientEmailView
