import { memo } from 'react'
import { classNames } from 'src/_shared/utils/elements'
import { formatDataTestId } from 'src/_shared/utils/string'

interface ProfileScreenLabelValueListItemProps {
	label: string
	layout: 'vertical' | 'horizontal'
	value: string
	containerClassName?: string
	labelClassName?: string
	dataTestIdPrefix?: string
}

const ProfileScreenLabelValueListItem = ({
	label,
	layout,
	value,
	containerClassName,
	labelClassName,
	dataTestIdPrefix = ''
}: ProfileScreenLabelValueListItemProps) => {
	return (
		<div
			className={classNames(
				layout === 'horizontal' ? 'flex-row gap-5' : 'mb-5 flex-col',
				'flex justify-between',
				containerClassName
			)}
		>
			<label
				data-testid={formatDataTestId([dataTestIdPrefix, 'label'])}
				className={classNames('mb-1 text-sm font-normal text-grayscale-600', labelClassName)}
			>
				{label}
			</label>
			<span
				data-testid={formatDataTestId([dataTestIdPrefix, 'value'])}
				className="body-2-normal text-typography-primary"
			>
				{value}
			</span>
		</div>
	)
}
const MemoisedProfileScreenLabelValueListItem = memo(ProfileScreenLabelValueListItem)

export default MemoisedProfileScreenLabelValueListItem
