import { SVGProps, memo } from 'react'

const ChargerChademoDcIcon = ({
	fill = 'none',
	stroke = 'currentColor',
	viewBox = '0 0 25 25',
	xmlns = 'http://www.w3.org/2000/svg',
	...props
}: SVGProps<SVGSVGElement>) => {
	return (
		<svg fill={fill} stroke={stroke} viewBox={viewBox} xmlns={xmlns} {...props}>
			<path d="M17.5989 15.6247C18.9818 15.6247 20.1029 14.4456 20.1029 12.9912C20.1029 11.5368 18.9818 10.3578 17.5989 10.3578C16.216 10.3578 15.0949 11.5368 15.0949 12.9912C15.0949 14.4456 16.216 15.6247 17.5989 15.6247Z" />
			<path d="M7.50485 15.6247C8.88779 15.6247 10.0089 14.4456 10.0089 12.9912C10.0089 11.5368 8.88779 10.3578 7.50485 10.3578C6.12192 10.3578 5.00082 11.5368 5.00082 12.9912C5.00082 14.4456 6.12192 15.6247 7.50485 15.6247Z" />
			<path d="M12.5001 9.16063C13.901 9.16063 15.0366 8.02502 15.0366 6.62417C15.0366 5.22332 13.901 4.08771 12.5001 4.08771C11.0993 4.08771 9.96368 5.22332 9.96368 6.62417C9.96368 8.02502 11.0993 9.16063 12.5001 9.16063Z" />
			<path d="M10.6317 7.24425C10.9596 7.24425 11.2255 6.96679 11.2255 6.62454C11.2255 6.28228 10.9596 6.00482 10.6317 6.00482C10.3037 6.00482 10.0378 6.28228 10.0378 6.62454C10.0378 6.96679 10.3037 7.24425 10.6317 7.24425Z" />
			<path d="M14.3174 7.24425C14.6454 7.24425 14.9113 6.96679 14.9113 6.62454C14.9113 6.28228 14.6454 6.00482 14.3174 6.00482C13.9895 6.00482 13.7236 6.28228 13.7236 6.62454C13.7236 6.96679 13.9895 7.24425 14.3174 7.24425Z" />
			<path d="M12.5265 9.03533C12.8545 9.03533 13.1203 8.75787 13.1203 8.41561C13.1203 8.07335 12.8545 7.7959 12.5265 7.7959C12.1986 7.7959 11.9327 8.07335 11.9327 8.41561C11.9327 8.75787 12.1986 9.03533 12.5265 9.03533Z" />
			<path d="M12.5265 5.42217C12.8545 5.42217 13.1203 5.14471 13.1203 4.80245C13.1203 4.4602 12.8545 4.18274 12.5265 4.18274C12.1986 4.18274 11.9327 4.4602 11.9327 4.80245C11.9327 5.14471 12.1986 5.42217 12.5265 5.42217Z" />
			<path d="M12.5001 21.8429C13.901 21.8429 15.0366 20.7073 15.0366 19.3065C15.0366 17.9056 13.901 16.77 12.5001 16.77C11.0993 16.77 9.96368 17.9056 9.96368 19.3065C9.96368 20.7073 11.0993 21.8429 12.5001 21.8429Z" />
			<path d="M10.6317 19.9266C10.9596 19.9266 11.2255 19.6491 11.2255 19.3068C11.2255 18.9646 10.9596 18.6871 10.6317 18.6871C10.3037 18.6871 10.0378 18.9646 10.0378 19.3068C10.0378 19.6491 10.3037 19.9266 10.6317 19.9266Z" />
			<path d="M14.3174 19.9266C14.6454 19.9266 14.9113 19.6491 14.9113 19.3068C14.9113 18.9646 14.6454 18.6871 14.3174 18.6871C13.9895 18.6871 13.7236 18.9646 13.7236 19.3068C13.7236 19.6491 13.9895 19.9266 14.3174 19.9266Z" />
			<path d="M12.5265 21.7176C12.8545 21.7176 13.1203 21.4402 13.1203 21.0979C13.1203 20.7557 12.8545 20.4782 12.5265 20.4782C12.1986 20.4782 11.9327 20.7557 11.9327 21.0979C11.9327 21.4402 12.1986 21.7176 12.5265 21.7176Z" />
			<path d="M12.5265 18.1045C12.8545 18.1045 13.1203 17.827 13.1203 17.4848C13.1203 17.1425 12.8545 16.8651 12.5265 16.8651C12.1986 16.8651 11.9327 17.1425 11.9327 17.4848C11.9327 17.827 12.1986 18.1045 12.5265 18.1045Z" />
			<path
				clipRule="evenodd"
				fillRule="evenodd"
				strokeWidth="2"
				d="M6.23666 3.82888C6.23666 3.82888 8.14213 2.76369 9.1743 2.43123C10.1883 2.10464 11.2538 1.87392 12.319 1.86183C13.5373 1.84799 14.7669 2.06685 15.9296 2.43123C16.9495 2.75089 18.8154 3.82888 18.8154 3.82888L20.2131 3.51829L22.1801 5.48534L21.766 6.93475C21.766 6.93475 22.8963 8.95794 23.1895 10.0665C23.4576 11.0799 23.517 12.1509 23.4743 13.1983C23.425 14.4057 23.2566 15.6309 22.8531 16.77C22.51 17.7384 21.9703 18.6413 21.3519 19.4618C20.7424 20.2705 20.0039 20.9879 19.2089 21.6152C18.5486 22.1361 17.8226 22.5854 17.0554 22.93C16.2738 23.2812 15.4368 23.5117 14.5966 23.6806C13.9386 23.8128 13.2645 23.8763 12.5933 23.8825C11.9311 23.8885 11.265 23.8372 10.6133 23.7194C9.94197 23.5981 9.27804 23.4141 8.64371 23.1629C7.92976 22.8802 7.23938 22.5255 6.59901 22.1018C5.74045 21.5336 4.91109 20.8958 4.21784 20.1347C3.5153 19.3634 2.90184 18.4906 2.45785 17.5465C2.04884 16.6768 1.81194 15.7254 1.6555 14.7771C1.49945 13.8312 1.44066 12.8589 1.52608 11.9041C1.61281 10.9348 1.86274 9.97949 2.17314 9.0571C2.43298 8.28496 3.18255 6.83122 3.18255 6.83122L2.8409 5.3197L4.63196 3.51829L6.23666 3.82888Z"
			/>
		</svg>
	)
}

const MemoisedChargerChademoDcIcon = memo(ChargerChademoDcIcon)

export default MemoisedChargerChademoDcIcon
