import { OmniConnectorStatus } from 'src/_shared/enums/omni'
import { OmniConnector } from 'src/_shared/types/omni'

/**
 * Determines if the target connector is available to the user by checking against
 * its current `status` and other relevant flags.
 * @param {OmniConnector} connector The target connector.
 * @returns {boolean} If `true`, then the target connector is available to the user.
 */
export const isConnectorAvailable = (connector: OmniConnector): boolean => {
	const isConnectorNotInUse =
		!!connector.status &&
		[OmniConnectorStatus.Available, OmniConnectorStatus.Preparing].includes(connector.status)

	const isConnectorEnabled = connector.is_enabled !== false

	const isConnectorOnline = connector.online !== false

	return isConnectorNotInUse && isConnectorEnabled && isConnectorOnline
}
