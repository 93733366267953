import { DetailedHTMLProps, ImgHTMLAttributes, memo } from 'react'

import appStoreBadge from './appStoreBadge.png'

const AppStoreBadgeImage = ({
	alt = 'Download on the App Store',
	...props
}: Omit<
	DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>,
	'src'
>): JSX.Element => {
	return <img alt={alt} src={appStoreBadge} {...props} />
}

const MemoisedAppStoreBadgeImage = memo(AppStoreBadgeImage)

export default MemoisedAppStoreBadgeImage
