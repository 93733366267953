/**
 * Concatenates provided class arguments together while removing any empty, `null`, or `undefined` arguments.
 * @param {Array<string|null|undefined>} args List of class arguments to concatenate together.
 * @returns {string} A single string containing concatenated classes.
 */
export const classNames = (...args: (string | null | undefined)[]): string => {
	return args
		.filter((arg): boolean => !!arg)
		.map((str): string => (str ?? '').trim())
		.join(' ')
}
