import { useCallback, useEffect } from 'react'
import { Navigate } from 'react-router-dom'
import { ScreenRoutePath, useQueryParams, useRouterNavigate } from 'src/App/router/hooks'
import Button from 'src/_shared/components/Button'
import InputOtp from 'src/_shared/components/InputOtp'
import Notice from 'src/_shared/components/Notice'
import Spinner from 'src/_shared/components/Spinner'
import { useAuthContext } from 'src/_shared/hooks/useAuthContext'
import { useUserOtpRequestMutation, useUserRegisterMutation } from 'src/_shared/mutations/auth'

import AccountScreenHeader from '../../../_shared/components/AccountScreenHeader'
import useCountdownTimer from '../../../_shared/hooks/useCountdownTimer'
import { FormFieldKey } from '../enums'
import { AccountRegistrationScreenCommonViewProps } from '../types'

type EnterOtpViewProps = AccountRegistrationScreenCommonViewProps

const EnterOtpView = ({
	formValues,
	setFormValues,
	shouldSendOtp,
	setShouldSendOtp
}: EnterOtpViewProps): JSX.Element => {
	const { countryCode, email, licensePlateNumber, mobileNumber, name, otp, password } = formValues

	const { isAuthenticated, setAuthTokens } = useAuthContext()

	const [{ redirect = '' }] = useQueryParams<{ redirect?: string }>()

	const [seconds, resetCountdownTimer] = useCountdownTimer()

	const navigate = useRouterNavigate()

	const {
		error: otpResendError,
		isError: isOtpResendError,
		isPending: isRequestOtpPending,
		mutateAsync: requestOtp,
		reset: resetUserOtpRequestMutation
	} = useUserOtpRequestMutation()

	const {
		error: registerUserError,
		isError: isRegisterUserError,
		isPending: isRegisterUserPending,
		isSuccess: isRegisterUserSuccess,
		mutateAsync: registerUser,
		reset: resetUserRegisterMutation
	} = useUserRegisterMutation()

	const errorMessage =
		registerUserError?.response?.data.message ?? otpResendError?.response?.data.message

	const handleOtpChange = useCallback(
		(value: string): void => {
			setFormValues(
				(values): Record<FormFieldKey, string> => ({
					...values,
					[FormFieldKey.Otp]: value
				})
			)
			if (isRegisterUserError && value !== otp) {
				resetUserRegisterMutation()
			}
		},
		[isRegisterUserError, otp, resetUserRegisterMutation, setFormValues]
	)

	const handleRequestOtpClick = useCallback((): void => {
		void requestOtp(
			{
				mobileNumber: countryCode + mobileNumber
			},
			{
				onSuccess: (): void => {
					resetUserOtpRequestMutation()
					resetCountdownTimer()
				}
			}
		)
	}, [countryCode, mobileNumber, requestOtp, resetCountdownTimer, resetUserOtpRequestMutation])

	const handleOtpFilled = useCallback(
		(value: string): void => {
			void registerUser(
				{
					name,
					mobileNumber: countryCode + mobileNumber,
					password,
					email,
					licensePlateNumber,
					otp: value
				},
				{
					onSuccess: ({ token: accessToken, refreshToken }): void => {
						setAuthTokens(accessToken, refreshToken)
					}
				}
			)
		},
		[
			countryCode,
			email,
			licensePlateNumber,
			mobileNumber,
			name,
			password,
			registerUser,
			setAuthTokens
		]
	)

	/**
	 * Check if we should send an otp immediately upon entering this page
	 * i.e. in the event that we came from the register screen
	 */
	useEffect(() => {
		if (!isAuthenticated && shouldSendOtp) {
			setShouldSendOtp(false)
			handleRequestOtpClick()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isAuthenticated, shouldSendOtp])

	if (isAuthenticated) {
		const redirectPath = redirect ? (redirect as ScreenRoutePath) : ScreenRoutePath.Map
		if (isRegisterUserSuccess) {
			return (
				<Notice
					type="success"
					header="Your account has been activated"
					buttonProps={{
						children: 'Continue',
						onClick: (): void => {
							resetUserRegisterMutation()
							navigate(redirectPath, { replace: true })
						}
					}}
				/>
			)
		}
		return <Navigate to={redirectPath} replace={true} />
	}

	return (
		<>
			<div className="flex flex-grow flex-col space-y-5">
				<AccountScreenHeader
					className="mb-[-4px]" // Account for margin in `subTitle`.
					title="Enter OTP"
					subTitle={
						<>
							We have sent a verification code to
							<span data-testid="ars-eov-text-mobile-no" className="body-1-semibold mt-1 block">
								{countryCode} {mobileNumber}
							</span>
						</>
					}
				/>
				<div className="flex flex-col items-center space-y-5">
					<InputOtp
						data-testid="ars-eov-input-otp"
						dataTestIdPrefix="ars-eov-input-otp"
						className="w-full"
						id={FormFieldKey.Otp}
						name={FormFieldKey.Otp}
						value={otp}
						disabled={isRegisterUserPending}
						error={isRegisterUserError || isOtpResendError}
						description={errorMessage}
						autoFocus={true}
						onChange={handleOtpChange}
						onFilled={handleOtpFilled}
					/>
					<p className="body-1-normal text-center">{seconds} Sec</p>
					{isRegisterUserPending && <Spinner className="h-5 w-5" />}
				</div>
			</div>
			<div className="flex flex-col items-center">
				<p
					data-testid="ars-eov-text-didnt-receive-otp"
					className="body-1-normal text-center text-typography-secondary"
				>
					Didn&apos;t receive the OTP code?
				</p>
				<Button
					data-testid="ars-eov-btn-resend-otp"
					variant="ghost"
					className="w-fit border-none"
					disabled={seconds > 0 || isRegisterUserPending || isRequestOtpPending}
					onClick={handleRequestOtpClick}
					textProps={{
						className: 'uppercase body-1-semibold'
					}}
				>
					Resend New Code
				</Button>
			</div>
		</>
	)
}

export default EnterOtpView
