import { SetupIntentResult, Stripe, StripeElements, StripeError } from '@stripe/stripe-js'
import { UseMutationOptions, useMutation } from '@tanstack/react-query'
import axios, { AxiosError, AxiosResponse } from 'axios'
import { ScreenRoutePath } from 'src/App/router/hooks'
import { CPO_BACKEND_URL } from 'src/_shared/constants/env'
import { useAuthContext } from 'src/_shared/hooks/useAuthContext'

interface AddStripePaymentMethodMutationParams {
	type: 'stripe'
	stripe: {
		card_name?: string
		token: string
	}
}

interface AddTngPaymentMethodMutationParams {
	type: 'tng'
	tng: {
		auth_code: string
	}
}

type AddPaymentMethodMutationParams =
	| AddStripePaymentMethodMutationParams
	| AddTngPaymentMethodMutationParams

interface AddPaymentMethodData {
	message: string
}

export const useAddPaymentMethodMutation = (
	options?: Omit<
		UseMutationOptions<
			AxiosResponse<AddPaymentMethodData>,
			AxiosError<{ message: string }>,
			AddPaymentMethodMutationParams
		>,
		'mutationFn'
	>
) => {
	const { accessToken } = useAuthContext()
	return useMutation({
		...options,
		mutationFn: async (
			params: AddPaymentMethodMutationParams
		): Promise<AxiosResponse<AddPaymentMethodData>> => {
			try {
				const response = await axios.post<AddPaymentMethodData>(
					`${CPO_BACKEND_URL}/v3/me/payment-method-tokens`,
					params,
					{
						headers: { Authorization: accessToken }
					}
				)
				return response
			} catch (error) {
				const axiosError = error as AxiosError
				return Promise.reject(axiosError)
			}
		}
	})
}

interface DeletePaymentMethodMutationParams {
	paymentMethodId: string
}

interface DeletePaymentMethodData {
	message: string
}

export const useDeletePaymentMethodMutation = (
	options?: Omit<
		UseMutationOptions<
			AxiosResponse<DeletePaymentMethodData>,
			AxiosError<{ message: string }>,
			DeletePaymentMethodMutationParams
		>,
		'mutationFn'
	>
) => {
	const { accessToken } = useAuthContext()
	return useMutation({
		...options,
		mutationFn: async ({
			paymentMethodId
		}: DeletePaymentMethodMutationParams): Promise<AxiosResponse<DeletePaymentMethodData>> => {
			try {
				const response = await axios.delete<DeletePaymentMethodData>(
					`${CPO_BACKEND_URL}/v3/me/payment-method-tokens/${paymentMethodId}`,
					{
						headers: { Authorization: accessToken }
					}
				)
				return response
			} catch (error) {
				const axiosError = error as AxiosError<{ message: string }>
				return Promise.reject(axiosError)
			}
		}
	})
}

interface StripeConfirmSetupIntentMutationParams {
	stripe: Stripe
	stripeElements: StripeElements
}

export const useStripeConfirmSetupIntentMutation = (
	options?: Omit<
		UseMutationOptions<SetupIntentResult, StripeError, StripeConfirmSetupIntentMutationParams>,
		'mutationFn'
	>
) => {
	return useMutation({
		...options,
		mutationFn: async ({
			stripe,
			stripeElements
		}: StripeConfirmSetupIntentMutationParams): Promise<SetupIntentResult> => {
			try {
				const response = await stripe.confirmSetup({
					confirmParams: {
						// Redirects back to Account Payment Methods Screen.
						return_url: [location.origin, ScreenRoutePath.AccountPaymentMethods].join('')
					},
					elements: stripeElements,
					redirect: 'if_required'
				})
				if (response.error) {
					return Promise.reject(response.error as unknown as Error)
				}
				return response
			} catch (error) {
				return Promise.reject(error as Error)
			}
		}
	})
}
