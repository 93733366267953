import { DetailedHTMLProps, ImgHTMLAttributes, memo } from 'react'

import jcbLogo from './jcbLogo.png'

const JcbLogoImage = ({
	alt = 'JCB',
	...props
}: Omit<
	DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>,
	'src'
>): JSX.Element => {
	return <img alt={alt} src={jcbLogo} {...props} />
}

const MemoisedJcbLogoImage = memo(JcbLogoImage)

export default MemoisedJcbLogoImage
