import { PropsWithChildren, ReactNode, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { ButtonProps } from 'src/_shared/components/Button'
import Notice from 'src/_shared/components/Notice'
import ScreenContainer from 'src/_shared/components/ScreenContainer'
import Spinner from 'src/_shared/components/Spinner'
import { BACK_LINK, BRAND } from 'src/_shared/constants/env'
import { Brand } from 'src/_shared/enums/env'
import { useAuthContext } from 'src/_shared/hooks/useAuthContext'
import { useMiniProgramContext } from 'src/_shared/hooks/useMiniProgramContext'
import { useUserSsoLoginMutation } from 'src/_shared/mutations/auth'

const SSO_RETRY_COUNT = 3

const SSO_TOKEN_KEY = 'sso_token'

/**
 * Handles SSO Login using the provided `sso_token` query parameter.
 */
const AuthSsoWrapper = ({ children }: PropsWithChildren): ReactNode => {
	const intl = useIntl()

	const { isAuthenticated, setAuthTokens } = useAuthContext()

	const { miniProgram } = useMiniProgramContext()

	const {
		error: userSsoLoginError,
		mutateAsync: ssoLogin,
		status: userSsoLoginMutationStatus
	} = useUserSsoLoginMutation({
		retry: SSO_RETRY_COUNT
	})

	const ssoTokenQueryParameter = new URL(window.location.href).searchParams.get(SSO_TOKEN_KEY)

	const errorMessage = userSsoLoginError?.response?.data.message

	const isSsoError =
		(!isAuthenticated && !ssoTokenQueryParameter) || userSsoLoginMutationStatus === 'error'

	const isSsoSuccess = isAuthenticated || userSsoLoginMutationStatus === 'success'

	useEffect((): void => {
		if (!isAuthenticated && !!ssoTokenQueryParameter) {
			void ssoLogin(
				{
					ssoToken: ssoTokenQueryParameter
				},
				{
					onSuccess: ({ token, refreshToken }): void => {
						setAuthTokens(token, refreshToken)
					}
				}
			)
		}
	}, [isAuthenticated, ssoTokenQueryParameter, setAuthTokens, ssoLogin])

	if (!isSsoSuccess) {
		return (
			<ScreenContainer
				contentViewProps={{
					className: 'px-5 py-6'
				}}
				hideTopBar
				hideBottomBar
			>
				{isSsoError ? (
					<Notice
						type="error"
						header={intl.formatMessage({
							id: 'Common.GenericErrorTitle',
							defaultMessage: 'Oops! Something Went Wrong'
						})}
						description={
							errorMessage
								? errorMessage
								: intl.formatMessage({
										id: 'Common.GenericErrorDescription',
										defaultMessage: 'Please try again later'
									})
						}
						buttonProps={((): Omit<ButtonProps, 'ref'> | null => {
							switch (BRAND) {
								case Brand.TouchNGo:
									return {
										children: intl.formatMessage({
											id: 'Common.GenericButtonTextBack',
											defaultMessage: 'Back'
										}),
										onClick: miniProgram
											? (): void => {
													// Relaunch the Mini Program and returns to the `index` screen.
													miniProgram.reLaunch({
														url: '/pages/index/index'
													})
												}
											: undefined
									}
								case Brand.Evme:
									return {
										children: intl.formatMessage({
											id: 'Common.GenericButtonTextBack',
											defaultMessage: 'Back'
										}),
										onClick: BACK_LINK
											? (): void => {
													window.location.href = BACK_LINK ?? ''
												}
											: undefined
									}
								default:
									return null
							}
						})()}
					/>
				) : (
					<div className="flex flex-grow flex-col items-center justify-center">
						<Spinner />
					</div>
				)}
			</ScreenContainer>
		)
	}
	return children
}

export default AuthSsoWrapper
