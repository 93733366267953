import {
	ChangeEvent,
	DetailedHTMLProps,
	InputHTMLAttributes,
	LabelHTMLAttributes,
	ReactNode,
	memo,
	useCallback
} from 'react'
import { classNames } from 'src/_shared/utils/elements'
import { formatDataTestId } from 'src/_shared/utils/string'

import CheckIcon from '../_icons/CheckIcon'

export type CheckboxProps = {
	/**
	 * Displayed to the left of the checkbox.
	 */
	label?: ReactNode
	/**
	 * The `label` node is wrapped inside of a `label` element.
	 */
	labelProps?: Omit<
		DetailedHTMLProps<LabelHTMLAttributes<HTMLLabelElement>, HTMLLabelElement>,
		'children' | 'htmlFor'
	>
	onChange?: (checked: boolean) => void
	dataTestIdPrefix?: string
} & Omit<
	DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
	'children' | 'onChange' | 'type'
>

const Checkbox = ({
	label,
	labelProps,
	checked,
	disabled,
	onChange,
	dataTestIdPrefix = '',
	...inputProps
}: CheckboxProps): JSX.Element => {
	const handleChange = useCallback(
		(event: ChangeEvent<HTMLInputElement>): void => {
			const checked = event.currentTarget.checked
			onChange?.(checked)
		},
		[onChange]
	)

	return (
		<div
			data-testid={formatDataTestId([dataTestIdPrefix, 'checkbox'])}
			className="flex flex-row items-center space-x-3"
		>
			<div className="relative h-4 w-4">
				{/* Hidden Checkbox */}
				{/* This will get clicked on and toggle the check icon. */}
				<input
					data-testid={formatDataTestId([dataTestIdPrefix, 'value-checkbox'])}
					{...inputProps}
					type="checkbox"
					className={classNames(
						// Base Classes
						'absolute h-full w-full appearance-none',
						// `disabled` Classes
						disabled ? 'cursor-not-allowed' : 'cursor-pointer',
						inputProps.className
					)}
					checked={checked}
					disabled={disabled}
					onChange={handleChange}
				/>
				<div
					className={classNames(
						// Base Classes
						'rounded-sm duration-75 ease-in-out',
						// `checked` Classes
						checked ? 'bg-primary-800' : 'border border-divider-primary',
						// `disabled` Classes
						disabled ? 'opacity-60' : null
					)}
				>
					<CheckIcon
						className={classNames(
							// Base Classes
							'rounded-sm',
							// `checked` Classes
							checked ? 'text-white' : 'invisible'
						)}
					/>
				</div>
			</div>
			<label
				data-testid={formatDataTestId([dataTestIdPrefix, 'label-checkbox'])}
				{...labelProps}
				htmlFor={inputProps.id}
				className={classNames(
					// Base Classes
					'body-1-normal',
					// `disabled` Classes
					disabled ? 'text-typography-tertiary' : 'text-typography-primary',
					labelProps?.className
				)}
			>
				{label}
			</label>
		</div>
	)
}

const MemoisedCheckbox = memo(Checkbox)

export default MemoisedCheckbox
