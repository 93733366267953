import dayjs from 'dayjs'
import LightningBoltFilledIcon from 'src/_shared/components/_icons/LightningBoltFilledIcon'
import { TIME_FORMAT } from 'src/_shared/constants/env'
import { OmniSession } from 'src/_shared/types/omni'
import { classNames } from 'src/_shared/utils/elements'
import { getPaymentBreakdown } from 'src/_shared/utils/receipt'
import { formatCurrencyValue, formatDataTestId } from 'src/_shared/utils/string'

interface HistoryItemProps {
	chargingSession: OmniSession
	dataTestIdPrefix?: string
}

const HistoryItem = ({ chargingSession, dataTestIdPrefix = '' }: HistoryItemProps) => {
	const paymentBreakdown = getPaymentBreakdown(chargingSession)

	return (
		<div className="flex items-center px-6 py-5">
			{/* Left Icon */}
			<div className="mr-4 flex items-center justify-center">
				<LightningBoltFilledIcon className="h-6 w-auto text-primary-800" />
			</div>
			{/* Center */}
			<div className="flex min-w-0 flex-col space-y-0.5">
				<p
					data-testid={formatDataTestId([dataTestIdPrefix, 'hi-location-name'])}
					className="body-2-medium truncate text-left text-typography-primary"
				>
					{chargingSession.location_name}
				</p>
				<p
					data-testid={formatDataTestId([dataTestIdPrefix, 'hi-total-kwh'])}
					className="caption-3-normal truncate text-left text-typography-tertiary"
				>
					{paymentBreakdown.totalKwh.toFixed(3)} kWh
				</p>
			</div>
			{/* Right */}
			<div className="flex min-w-20 flex-grow flex-col space-y-0.5">
				<p
					data-testid={formatDataTestId([dataTestIdPrefix, 'hi-payment-amount'])}
					className={classNames(
						'body-2-medium text-right',
						paymentBreakdown.isCostFree
							? 'text-typography-tertiary line-through'
							: 'text-typography-primary'
					)}
				>
					{formatCurrencyValue(paymentBreakdown.totalCost, chargingSession.currency, 2)}
				</p>
				<p
					data-testid={formatDataTestId([dataTestIdPrefix, 'hi-charging-start-dt'])}
					className="caption-3-normal whitespace-nowrap text-right text-typography-tertiary"
				>
					{dayjs(chargingSession.start_date_time).format(TIME_FORMAT)}
				</p>
			</div>
		</div>
	)
}

export default HistoryItem
