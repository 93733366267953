import MarkdownIt from 'markdown-it'

/**
 * Checks if the given `text` contains html.
 * @param {string} text The `text` to check.
 * @returns {boolean} A boolean indicating whether the `text` contains html.
 */
export const containsHtml = (text: string): boolean => {
	const htmlPattern = /<\/?[a-z][\s\S]*>/i

	// Test if the string contains any HTML tags
	return htmlPattern.test(text)
}

/**
 * Parses the given markdown `text` and returns the rendered `HTML`.
 * @param {string} text The markdown `text` to parse.
 * @returns {string} The rendered `HTML`.
 */
export const parseMarkdown = (text: string): string => {
	const md = new MarkdownIt({
		html: true,
		breaks: true // To render single line break
	})

	// Render the markdown text as HTML
	return md.render(text)
}

/**
 * Sanitizes the given `text` by removing all HTML tags.
 * @param {string} text The `text` to be sanitized.
 * @returns {string} The sanitized `text`.
 */
export const sanitiseText = (text: string): string => {
	// Replace all HTML tags with an empty string
	return text.replace(/<\/?[^>]+(>|$)/g, '')
}
