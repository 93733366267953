import { useMemo, useState } from 'react'
import LocationPin from 'src/_shared/_old/assets/svgs/LocationPin'
import PageContainer from 'src/_shared/_old/components/containers/PageContainer'
import HeaderBar from 'src/_shared/_old/components/list/HeaderBar'
import NoCharger from 'src/_shared/_old/components/list/NoCharger'
import RatingBoxes from 'src/_shared/_old/components/list/RatingBoxes'
import Topbar from 'src/_shared/_old/components/nav/Topbar'
import LocationListSkel from 'src/_shared/_old/components/skeletons/LocationListSkel'
import { PowerType, TOPBAR_HEIGHT } from 'src/_shared/_old/enums'
import { Coordinates } from 'src/_shared/_old/schemas/geolocation'
import { Filter, OmniLocation } from 'src/_shared/_old/schemas/typings'
import {
	getConnectorCounts,
	getHighestTariffPriceValue,
	getLocationDetails
} from 'src/_shared/_old/utils/charging'
import { filterCDGEAddressName } from 'src/_shared/_old/utils/format'
import { convertDistanceWithoutMetric } from 'src/_shared/_old/utils/math'
import PriceModifierTag from 'src/_shared/components/PriceModifierTag'
import Tag from 'src/_shared/components/Tag'
import { OmniCountryCurrencyCode, OmniTariffPriceModifierType } from 'src/_shared/enums/omni'
import { useStrapiContext } from 'src/_shared/hooks/useStrapiContext'
import { classNames } from 'src/_shared/utils/elements'

import SearchChargerScreen from './SearchChargerScreen'

interface ListViewScreenProps {
	filters?: Filter
	locations?: OmniLocation[]
	locationsLoading?: boolean
	backFunction?: (coords: Coordinates) => void
	onClickLoc?: (location: OmniLocation) => void
	onSetSearchCharger: (toShowSearchCharger: boolean) => void
	onSetLocationTag: (name: string) => void
	onSetNewCoords: (searchedCoordinates: Coordinates) => void
	onClearLocationPin: () => void
	currentCoords: Coordinates
	actualCoords: Coordinates
	isCurrentCoordsAvailable: boolean
	locationName?: string | null
	map: google.maps.Map | null
	maps: typeof google.maps | null
	isAuthenticated: boolean
	subscribedCpoEntities?: string[]
}

interface TwentyFourSevenTagProps {
	className?: string
}

// Note: If this gets reused in other screens or components, then extract it out.
const TwentyFourSevenTag = ({ className }: TwentyFourSevenTagProps): JSX.Element => {
	const { brandData } = useStrapiContext()
	return (
		<Tag
			data-testid="map-lvs-24-7-tag"
			value="24 Hours"
			className={className}
			style={brandData?.attributes.theme?.components?.twentyFourSevenTag}
		/>
	)
}

const ListViewScreen = ({
	filters,
	locations,
	locationsLoading,
	backFunction,
	onClickLoc,
	actualCoords,
	onSetSearchCharger,
	onSetLocationTag,
	onClearLocationPin,
	currentCoords,
	isCurrentCoordsAvailable,
	onSetNewCoords,
	locationName,
	map,
	maps,
	isAuthenticated,
	subscribedCpoEntities
}: ListViewScreenProps) => {
	const [showChargerScreen, setShowChargerScreen] = useState(false)

	const handleChosenLocation = (chosenCoords?: Coordinates, name?: string) => {
		if (chosenCoords) {
			// this line is needed for ListViewScreen to rerender in order for the currentCoords to update itself
			onSetNewCoords(chosenCoords)
		}
		// this line is needed for ListViewScreen to rerender in order for the locationTag to update itself
		onSetLocationTag(name ?? '')
		// if there is location tag name, this would mean that we would have to clear location pins on the map
		if (!name) {
			onClearLocationPin()
		}
	}

	// the return value of this function would determine whether is there a need for the location pin to be dropped or not
	const handleLocationPinParameters = () => {
		return currentCoords
	}

	/* ranking locations
  CASE 1: location access granted, no POI chosen
    rank the locations based on distance (nearest to furthest)
    if any 2 locations have the same distance, rank them based on alphabetical order

  CASE 2: location access not granted, no POI chosen
    rank the locations based on alphabetical order

  CASE 3: POI has been chosen
    rank the locations same as condition in 1 irregardless of location access
  */
	const rankedLocations = useMemo(() => {
		// should only be called for case 1
		if (!(actualCoords.lat && actualCoords.lng) && !(currentCoords.lat && currentCoords.lng)) {
			return locations?.sort((x, y) => x.name.localeCompare(y.name))
		}

		const sortedLocations = locations?.sort((x, y) => {
			const distanceX = convertDistanceWithoutMetric(
				getLocationDetails(x, currentCoords).distance ?? ''
			)
			const distanceY = convertDistanceWithoutMetric(
				getLocationDetails(y, currentCoords).distance ?? ''
			)

			if (distanceX - distanceY === 0.0) {
				return x.name.localeCompare(y.name)
			} else return distanceX - distanceY
		})

		return sortedLocations
	}, [locations, actualCoords.lat, actualCoords.lng, currentCoords])

	return (
		<>
			{!showChargerScreen ? (
				<PageContainer scroll={false}>
					<Topbar
						title="Charger List"
						backFunction={() => backFunction?.(handleLocationPinParameters())}
						showBackIcon
					/>

					{/* Search Bar Portion */}
					<div className={`mb-2 w-full px-2`} style={{ marginTop: TOPBAR_HEIGHT }}>
						<div
							className={`border-1.5 z-20 flex w-full items-center justify-center space-x-3 rounded-xl border border-primary-400/30 px-3 py-3`}
						>
							<LocationPin className="h-5 w-auto text-typography-tertiary" />
							<button
								className={`w-full overflow-hidden text-ellipsis whitespace-nowrap bg-white text-left focus:bg-white ${
									isCurrentCoordsAvailable && !locationName
										? 'font-bold text-light-blue-700'
										: 'font-light text-typography-secondary'
								} outline-none focus:outline-none`}
								onClick={() => {
									setShowChargerScreen(true)
									onSetSearchCharger(true)
								}}
							>
								{locationName ??
									(isCurrentCoordsAvailable ? 'Current Location' : 'Find charging location here')}
							</button>
						</div>
					</div>

					{/* Header Bar Portion */}
					<HeaderBar />

					<div
						className={`w-full overflow-x-hidden px-5 overflow-y-${
							locationsLoading ? 'hidden' : 'scroll'
						}`}
					>
						<div className="flex w-full flex-col pb-12">
							{locationsLoading ? (
								<LocationListSkel number={10} />
							) : rankedLocations && rankedLocations.length > 0 ? (
								rankedLocations.map((location, index) => {
									const evseWithPriceModifier = location.evses.find((evse) =>
										// As long as there's at least 1 connector with a price modifier,
										// we display that found price modifier. This also assumes that
										// the same price modifier is applied to all connectors, although
										// it might be possible that it will not be so in the future.
										// TODO: Revisit this when it is brought up.
										evse.connectors.some(
											(connector) => (connector.active_tariff?.price_modifiers ?? []).length > 0
										)
									)
									const priceModifier =
										evseWithPriceModifier?.connectors[0]?.active_tariff?.price_modifiers?.[0]
									const hasIndicators = location.opening_times.twentyfourseven || !!priceModifier
									const locationDetails = getLocationDetails(location, currentCoords)
									const isUserSubscribed = subscribedCpoEntities?.includes(location.entity_code)
									return (
										<button
											key={index}
											className={`flex w-full items-center justify-between ${
												!!locations &&
												index !== locations.length - 1 &&
												'border-b border-primary-400/30'
											} py-3`}
											onClick={() => onClickLoc?.(location)}
										>
											{/* Logo Section */}
											<div className="h-[50px] w-[50px] self-start object-contain pt-1.5">
												<img src={location.operator.logo} />
											</div>
											{/* Information Section */}
											<div className="w-7/12 flex-none flex-col items-center justify-start self-start px-2 text-left">
												<p className="w-full text-wrap text-[0.9rem] font-bold">{location.name}</p>
												<div className="flex w-full space-x-2">
													{filters?.powerTypeFilter !== PowerType.DC &&
														getConnectorCounts(location.evses).totalACCount > 0 && (
															<div>
																<span className="text-xs">AC: </span>
																<span
																	className={classNames(
																		'text-xs font-medium text-typography-primary',
																		isUserSubscribed ? 'line-through' : null
																	)}
																>
																	{getHighestTariffPriceValue(
																		location.evses,
																		PowerType.AC,
																		isAuthenticated
																	)}
																</span>
															</div>
														)}

													{filters?.powerTypeFilter !== PowerType.AC &&
														getConnectorCounts(location.evses).totalDCCount > 0 && (
															<div>
																<span className="text-xs">DC: </span>
																<span
																	className={classNames(
																		'text-xs font-medium text-typography-primary',
																		isUserSubscribed ? 'line-through' : null
																	)}
																>
																	{getHighestTariffPriceValue(
																		location.evses,
																		PowerType.DC,
																		isAuthenticated
																	)}
																</span>
															</div>
														)}
												</div>
												{hasIndicators && (
													<div className="mb-1 mt-0.5 flex space-x-0.5">
														{location.opening_times.twentyfourseven && (
															<TwentyFourSevenTag className="truncate" />
														)}
														{priceModifier && isAuthenticated && (
															<PriceModifierTag
																dataTestIdPrefix="map-lvs"
																className="truncate"
																currencyCode={
																	evseWithPriceModifier.connectors[0]?.active_tariff
																		?.currency as OmniCountryCurrencyCode
																}
																priceModifierType={
																	priceModifier.type as unknown as OmniTariffPriceModifierType
																}
																priceModifierValue={priceModifier.value}
															/>
														)}
														{isUserSubscribed && (
															<Tag
																data-testid="map-lvs-subscribed-tag"
																className="truncate bg-orange-400 text-white"
																value="Subscribed"
															/>
														)}
													</div>
												)}
												<p className="space-x-1.5 text-xs font-light text-typography-secondary">
													{locationDetails.distance && (
														<span className="font-semibold">{locationDetails.distance}</span>
													)}
													<span>{filterCDGEAddressName(location.address)}</span>
												</p>
											</div>
											{/* Blue and Orange Button Section */}
											<RatingBoxes
												evses={location.evses}
												operatorName={location.operator.name}
												powerFilter={filters?.powerTypeFilter}
											/>
										</button>
									)
								})
							) : (
								<NoCharger
									header="No Chargers found in this location"
									mainText="Change your position or search for a location with charging location nearby"
								/>
							)}
						</div>
					</div>
				</PageContainer>
			) : (
				<SearchChargerScreen
					currentCoords={currentCoords}
					actualCoords={actualCoords}
					map={map}
					maps={maps}
					onReturn={() => {
						setShowChargerScreen(false)
						onSetSearchCharger(false)
					}}
					onChooseMatchedLocation={handleChosenLocation}
					isCurrentCoordsAvailable={isCurrentCoordsAvailable}
				/>
			)}
		</>
	)
}

export default ListViewScreen
