import { Language } from '../enums/env'
import { OmniCountryCurrencyCode } from '../enums/omni'
import { getCurrencyDetails } from './currency'

/**
 * Formats a given currency value based on the provided currency code.
 * @param {number} value The numeric currency value.
 * @param {OmniCountryCurrencyCode} currencyCode The currency code of the country using the currency.
 * @param {number} fractionDigits Number of digits after the decimal point. Must be in the range 0 - 20, inclusive.
 * @returns {string} The formatted currency value. This value will be in a 3 d.p. format, e.g. `$1.234`.
 */
export const formatCurrencyValue = (
	value = 0,
	currencyCode = OmniCountryCurrencyCode.Singapore,
	fractionDigits = 3
): string => {
	const { prefix: currencyPrefix, suffix: currencySuffix } = getCurrencyDetails(currencyCode)
	return `${currencyPrefix}${value.toFixed(fractionDigits)}${currencySuffix}`
}

/**
 * Formats an array of strings into a data test ID by replacing whitespace characters with hyphens and joining them with hyphens.
 * @param {string[]} strings The array of strings to format.
 * @returns {string} The formatted data test ID.
 */
export const formatDataTestId = (strings: string[]): string => {
	return strings
		.filter((string) => string.length > 0)
		.map((string) => formatStringWithHyphens(string.toLowerCase()))
		.join('-')
}

/**
 * Replaces whitespace characters in a `string` with hyphens.
 * @param {string} inputString The `inputString` to format.
 * @returns {string} The formatted `string` with hyphens instead of whitespace.
 */
export const formatStringWithHyphens = (inputString: string): string => {
	return inputString.replace(/\s/g, '-')
}

/**
 * Determines if the provided `value` is a valid `Language`.
 * @param {string} value The `value` to check.
 * @returns {value is Language} The type predicate of `value`.
 */
export const isValidLanguage = (value: string | null): value is Language => {
	return !!value && Object.values<string>(Language).includes(value)
}
