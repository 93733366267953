import { createContext, useContext } from 'react'

import { StrapiBrandData, StrapiFeatureToggles } from '../queries/strapi'

/**
 * Function Overload
 */
interface GetFeatureTogglesFunction {
	(): StrapiFeatureToggles | null
	<T extends keyof StrapiFeatureToggles>(screen: T): StrapiFeatureToggles[T] | null
}

interface StrapiContextData {
	brandData: StrapiBrandData | null
	/**
	 * Get Feature Toggles entirely or by `screen` level.
	 * @param {keyof StrapiFeatureToggles | undefined} screen The `screen` to get Feature Toggles for.
	 * @returns {StrapiFeatureToggles | StrapiFeatureToggles[T] | null} If `screen` is provided, return
	 * the Feature Toggles for that screen. Otherwise, return all possible Feature Toggles.
	 */
	getFeatureToggles: GetFeatureTogglesFunction
}

export const StrapiContext = createContext<StrapiContextData>({
	brandData: null,
	getFeatureToggles: (): null => {
		console.debug('[StrapiBrandWrapper] getFeatureToggles was not provided.')
		return null
	}
})

export const useStrapiContext = (): StrapiContextData => {
	const context = useContext(StrapiContext)
	return context
}
