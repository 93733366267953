import { DetailedHTMLProps, memo } from 'react'
import { useStrapiContext } from 'src/_shared/hooks/useStrapiContext'
import { classNames } from 'src/_shared/utils/elements'

type TopBarButtonProps = DetailedHTMLProps<
	React.ButtonHTMLAttributes<HTMLButtonElement>,
	HTMLButtonElement
> & {
	'data-testid'?: string
}

/**
 * Styled Button Component specifically for `TopBar`.
 */
const TopBarButton = ({
	className,
	'data-testid': dataTestId,
	...props
}: TopBarButtonProps): JSX.Element => {
	const { brandData } = useStrapiContext()

	const { color } = brandData?.attributes.theme?.components?.topBar ?? {}

	return (
		<button
			data-testid={dataTestId}
			className={classNames('flex h-full w-full items-center justify-center', className)}
			{...props}
			style={{
				color,
				...props.style
			}}
		/>
	)
}

const MemoisedTopBarButton = memo(TopBarButton)

export default MemoisedTopBarButton
