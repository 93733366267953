import {
	DetailedHTMLProps,
	HTMLAttributes,
	memo,
	useCallback,
	useEffect,
	useRef,
	useState
} from 'react'
import { useSwipeable } from 'react-swipeable'
import CheckIcon from 'src/_shared/components/_icons/CheckIcon'
import ChevronRightIcon from 'src/_shared/components/_icons/ChevronRightIcon'
import { classNames } from 'src/_shared/utils/elements'
import { formatDataTestId } from 'src/_shared/utils/string'

export type SwipeableButtonProps = Omit<
	DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
		/**
		 * The minimum distance (in px) before a swipe starts.
		 */
		delta?: number
		disabled?: boolean
		/**
		 * Minimum fraction of the button width to exceed to determine when a swipe is intended for completion.
		 */
		swipeWidthThreshold?: number
		/**
		 * Minimum swipe velocity to exceed to determine when a swipe is intended for completion.
		 */
		velocityThreshold?: number
		/**
		 * If provided, do ensure that the function is memoised with `useCallback`.
		 */
		onSwipeComplete?: () => void
		/**
		 * `Button` text is wrapped inside of a `span` element.
		 */
		textProps?: Omit<
			DetailedHTMLProps<HTMLAttributes<HTMLSpanElement>, HTMLSpanElement>,
			'children'
		>
	},
	'ref'
> & {
	dataTestIdPrefix?: string
}

const SWIPEABLE_BUTTON_MARGIN = 4

// Tailwind `duration-150` class
const SWIPE_TRANSITION_DELAY = 150

const SwipeableButton = ({
	children,
	className,
	delta = 10,
	disabled,
	textProps,
	swipeWidthThreshold = 0.6,
	velocityThreshold = 0.6,
	onSwipeComplete,
	dataTestIdPrefix = '',
	...buttonProps
}: SwipeableButtonProps): JSX.Element => {
	const [isSwipeComplete, setIsSwipeComplete] = useState<boolean>(false)

	const buttonRef = useRef<HTMLDivElement>(null)

	const buttonOverlayRef = useRef<HTMLDivElement>(null)

	const swipeableButtonWidth = buttonRef.current?.offsetHeight ?? 48

	/**
	 * Calculates the horizontal distance of `buttonRef` from the left edge of the window.
	 */
	const getSwipeableLeftOffset = (): number => {
		if (buttonRef.current) {
			const rect = buttonRef.current.getBoundingClientRect()
			return rect.left + window.scrollX
		}
		return 0
	}

	// Set the width via ref so that it doesn't cause re-rendering every time the function is called.
	const setButtonOverlayWidth = useCallback((width: number): void => {
		if (buttonOverlayRef.current) {
			buttonOverlayRef.current.style.width = `${Math.round(width)}px`
		}
	}, [])

	const swipeableHandlers = useSwipeable({
		onSwipedRight: ({ event, initial, velocity }): void => {
			if (isSwipeComplete || disabled) {
				return
			}

			const buttonWidth = buttonRef.current?.offsetWidth ?? 0

			// If swipe was quick, shift the swipeable knob to the end.
			if (velocity > velocityThreshold) {
				setButtonOverlayWidth(buttonWidth)
				setIsSwipeComplete(true)
			}
			// Determine swipe completion based on the swipe-end position.
			else {
				const leftOffset = getSwipeableLeftOffset()

				const startPosititon = Math.abs(initial[0] - leftOffset)

				// If the knob crosses a certain percentage of the `buttonRef` width (e.g. default 60%),
				// then the threshold would be exceeded and hence the swipe should be deemed as complete.
				const isSwipeWidthThresholdExceeded = ((): boolean => {
					// Mouse Drag
					if (event instanceof MouseEvent) {
						return event.clientX - leftOffset > swipeWidthThreshold * buttonWidth
					}
					// Touch Drag
					else if (event instanceof TouchEvent && event.type === 'touchend') {
						return event.changedTouches[0].clientX - leftOffset > swipeWidthThreshold * buttonWidth
					}
					return false
				})()

				// If the width threshold has been exceeded, shift the swipeable knob to the end.
				if (startPosititon <= swipeableButtonWidth && isSwipeWidthThresholdExceeded) {
					setButtonOverlayWidth(buttonWidth)
					setIsSwipeComplete(true)
				}
				// Reset swipeable knob position.
				else {
					setButtonOverlayWidth(swipeableButtonWidth)
				}
			}
		},
		onSwiping: ({ event, initial }): void => {
			if (isSwipeComplete || disabled) {
				return
			}
			const leftOffset = getSwipeableLeftOffset()

			// Start drag position relative to the left of the `buttonRef` container.
			const startPosition = Math.abs(initial[0] - leftOffset)

			// Ensure that the drag begins within the knob and when the knob is at its leftmost position.
			if (startPosition <= swipeableButtonWidth) {
				const buttonWidth = buttonRef.current?.offsetWidth ?? 0

				// Calculate how much the knob has moved to the right. This is bounded by the full width of `buttonRef`.
				const buttonSubWidth = Math.min(
					((): number => {
						// Mouse Drag
						if (event instanceof MouseEvent) {
							return event.clientX - leftOffset
						}
						// Touch Drag
						else if (event instanceof TouchEvent && event.type === 'touchmove') {
							return event.touches[0].clientX - leftOffset
						}
						return 0
					})(),
					buttonWidth
				)

				// Ensures that the knob is always visually within the `buttonRef` (and does not go out via the left side).
				const width = Math.max(buttonSubWidth, swipeableButtonWidth)

				setButtonOverlayWidth(width)
			}
		},
		delta,
		trackMouse: true,
		preventScrollOnSwipe: true
	})

	/**
	 * Fires the `onSwipeComplete` callback after the knob transition is done.
	 */
	useEffect(() => {
		if (isSwipeComplete && onSwipeComplete) {
			const timeoutId = setTimeout((): void => {
				onSwipeComplete()
			}, SWIPE_TRANSITION_DELAY)
			return (): void => {
				clearTimeout(timeoutId)
			}
		}
	}, [isSwipeComplete, onSwipeComplete])

	/**
	 * Initialise `useSwipeable` handlers `ref`.
	 */
	useEffect((): void => {
		if (buttonRef.current) {
			swipeableHandlers.ref(buttonRef.current)
		}
	}, [swipeableHandlers])

	return (
		<div
			data-testid={formatDataTestId([dataTestIdPrefix, 'btn-swipeable'])}
			{...buttonProps}
			{...swipeableHandlers}
			className={classNames(
				'btn relative w-full select-none !rounded-swipeable-button',
				disabled ? 'disabled primary' : 'primary !bg-secondary-300',
				isSwipeComplete ? '!bg-none' : null,
				className
			)}
			ref={buttonRef}
		>
			{/* Button Swipeable Overlay */}
			<div
				className={classNames(
					'absolute left-0 z-10 flex h-full select-none items-center justify-end rounded-swipeable-button duration-150 ease-out',
					disabled ? 'cursor-not-allowed' : null
				)}
				ref={buttonOverlayRef}
				style={{
					paddingRight: SWIPEABLE_BUTTON_MARGIN,
					minWidth: swipeableButtonWidth
				}}
			>
				{/* Swipeable Knob */}
				<div
					data-testid={formatDataTestId([dataTestIdPrefix, 'knob-swipeable'])}
					className={classNames(
						'flex items-center justify-center rounded-swipeable-button bg-white shadow-lg',
						disabled ? null : 'cursor-pointer'
					)}
					style={{
						height: swipeableButtonWidth - SWIPEABLE_BUTTON_MARGIN * 2,
						width: swipeableButtonWidth - SWIPEABLE_BUTTON_MARGIN * 2
					}}
				>
					{isSwipeComplete ? (
						<CheckIcon
							className={classNames(
								'h-6 w-6',
								disabled ? 'text-typography-tertiary/40' : 'text-success-400'
							)}
						/>
					) : (
						<ChevronRightIcon
							className={classNames(
								'h-7 w-7',
								disabled ? 'text-typography-secondary/40' : 'text-typography-secondary'
							)}
						/>
					)}
				</div>
			</div>
			{/* Button Text */}
			<div>
				<span
					{...textProps}
					className={classNames(
						'body-2-semibold whitespace-nowrap',
						disabled ? '!animate-none text-typography-primary/40' : 'text-button-primary-content',
						isSwipeComplete ? null : ' animate-pulse',
						textProps?.className
					)}
				>
					{children}
				</span>
			</div>
		</div>
	)
}

const MemoisedSwipeableButton = memo(SwipeableButton)

export default MemoisedSwipeableButton
