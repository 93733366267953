import { createContext, useContext } from 'react'

import { MiniProgramApi } from '../types/miniProgram'

interface MiniProgramContextData {
	miniProgram: MiniProgramApi | null
}

export const MiniProgramContext = createContext<MiniProgramContextData>({
	miniProgram: null
})

export const useMiniProgramContext = (): MiniProgramContextData => {
	const context = useContext(MiniProgramContext)
	return context
}
