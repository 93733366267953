import { ScreenRoutePath, useRouterNavigate } from 'src/App/router/hooks'
import IconAcCharger from 'src/_shared/_old/assets/svgs/AcCharger.svg'
import IconDcCharger from 'src/_shared/_old/assets/svgs/DcCharger.svg'
import { PowerType } from 'src/_shared/_old/enums'
import { OmniPriceModifier } from 'src/_shared/_old/schemas/chargingSession'
import { OmniConnector, OmniEVSE } from 'src/_shared/_old/schemas/evse'
import {
	getConnectorPowerType,
	getConnectorPowerRating,
	handleCheckAvailability,
	getConnectorTariffPriceValue
} from 'src/_shared/_old/utils/charging'
import Tag from 'src/_shared/components/Tag'
import { OmniCountryCurrencyCode, OmniTariffPriceModifierType } from 'src/_shared/enums/omni'
import { classNames } from 'src/_shared/utils/elements'

import PriceModifierTag from '../../../components/PriceModifierTag'

interface Props {
	locationId: string
	isSubscription: boolean
	connector: OmniConnector
	evse: OmniEVSE
	operatorName: string
	cpoEntityCode: string
	index: number
	priceModifiers: OmniPriceModifier[]
	isAuthenticated: boolean
}

const ChargerButton = ({
	locationId,
	isSubscription,
	evse,
	connector,
	cpoEntityCode,
	priceModifiers,
	isAuthenticated
}: Props) => {
	const navigate = useRouterNavigate()

	const power = getConnectorPowerRating(connector)

	const powerType = getConnectorPowerType(connector)

	const priceModifiersApplied = priceModifiers.length !== 0

	return (
		<button
			className="flex w-full flex-row items-center px-4"
			onClick={() => {
				// GUARD CONDITION: against random uids of evses and connectors
				if (evse.uid && connector.uid) {
					navigate([ScreenRoutePath.Charger, cpoEntityCode, locationId, evse.uid, connector.uid], {
						state: priceModifiers
					})
				} else {
					navigate(ScreenRoutePath.Map)
				}
			}}
		>
			<div className="flex w-full flex-row items-center border-t border-primary-400/30 py-5">
				<div className="mr-2.5 w-fit">
					<div
						className={classNames(
							'flex h-[41px] w-[41px] items-center justify-center rounded-full border-[3px]',
							handleCheckAvailability(evse) ? 'border-success-400' : 'border-error-300'
						)}
					>
						<img src={powerType === PowerType.AC ? IconAcCharger : IconDcCharger} />
					</div>
				</div>
				<div className="flex w-full flex-col items-start justify-center">
					<div className="flex w-fit items-center justify-center">
						<p className="mr-1.5 text-left text-sm font-semibold text-typography-primary">
							{`${connector.physical_reference ? `[${connector.physical_reference}] ` : ''}${evse.physical_reference || evse.evse_id} (${power} kW/${powerType})`}
						</p>

						<div className="flex w-fit items-start justify-start">
							{
								priceModifiersApplied &&
									isAuthenticated &&
									priceModifiers.map((modifier: OmniPriceModifier, index: number) => {
										return (
											<PriceModifierTag
												key={index}
												currencyCode={connector.active_tariff?.currency as OmniCountryCurrencyCode}
												priceModifierValue={modifier.value}
												priceModifierType={modifier.type as unknown as OmniTariffPriceModifierType}
											/>
										)
									})[0] // Assume that there's at most 1 active Price Modifier (for now).
								// TODO: Revisit this when it is brought up.
							}
						</div>
					</div>
					<div className="flex flex-row items-center space-x-1">
						{isSubscription ? (
							<>
								<span
									className={classNames(
										'text-sm font-medium text-typography-secondary',
										priceModifiersApplied && isAuthenticated ? 'line-through' : null
									)}
								>
									{priceModifiersApplied && isAuthenticated
										? getConnectorTariffPriceValue(connector, 'active_tariff')
										: getConnectorTariffPriceValue(connector, 'original_tariff')}
								</span>
								<Tag className="truncate bg-orange-400 text-white" value="Subscribed" />
							</>
						) : (
							<>
								<span
									className={classNames(
										'text-sm font-medium text-typography-secondary',
										priceModifiersApplied && isAuthenticated ? 'line-through' : null
									)}
								>
									{getConnectorTariffPriceValue(connector, 'original_tariff')}
								</span>
								{priceModifiersApplied && isAuthenticated && (
									<span className="text-sm font-medium text-typography-secondary">
										{getConnectorTariffPriceValue(connector, 'active_tariff')}
									</span>
								)}
							</>
						)}
					</div>
				</div>
			</div>
		</button>
	)
}

export default ChargerButton
