import dayjs from 'dayjs'
import { ReactNode, useCallback, useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { ScreenRoutePath } from 'src/App/router/hooks'
import Button from 'src/_shared/components/Button'
import { NoticeProps } from 'src/_shared/components/Notice'
import PaymentMethodsList from 'src/_shared/components/PaymentMethodsList'
import Spinner from 'src/_shared/components/Spinner'
import CheckCircleIcon from 'src/_shared/components/_icons/CheckCircleIcon'
import { BRAND } from 'src/_shared/constants/env'
import { Brand } from 'src/_shared/enums/env'
import { OmniError } from 'src/_shared/enums/omni'
import useAddTngPaymentMethod from 'src/_shared/hooks/useAddTngPaymentMethod'
import { useStartChargingSessionMutation } from 'src/_shared/mutations/sessions'
import { usePaymentMethodsQuery } from 'src/_shared/queries/payments'
import { PaymentMethod } from 'src/_shared/types/payments'
import { classNames } from 'src/_shared/utils/elements'
import { formatDataTestId } from 'src/_shared/utils/string'

import { ChargerScreenViewItemKey } from '../enums'
import useChargerDebugLogging from '../hooks/useChargerDebugLogging'
import { useChargerDetails } from '../hooks/useChargerDetails'
import { useChargerSessionDetails } from '../hooks/useChargerSessionDetails'
import { ChargerScreenCommonViewProps } from '../types'
import Footer from './Footer'
import TermsAndConditionsBottomRender from './TermsAndConditionsBottomRender'

type PaymentMethodsViewProps = ChargerScreenCommonViewProps

const PaymentMethodsView = ({
	routeParams,
	onNext: handleNext,
	updateView
}: PaymentMethodsViewProps): JSX.Element => {
	const [defaultPaymentMethod, setDefaultPaymentMethod] = useState<PaymentMethod | null>(null)

	const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<PaymentMethod | null>(null)

	const intl = useIntl()

	const { data: paymentMethods = [], isLoading: isPaymentMethodsLoading } = usePaymentMethodsQuery()

	const { connector } = useChargerDetails(routeParams)

	const { session, isUserChargingSession, resetChargerSessionDetailsQueries } =
		useChargerSessionDetails(routeParams)

	const {
		isPending: isStartChargingSessionPending,
		isSuccess: isStartChargingSessionSuccess,
		mutateAsync: startChargingSession
	} = useStartChargingSessionMutation()

	const { addTngPaymentMethod, isAddTngPaymentMethodPending, isTngSetupIntentLoading } =
		useAddTngPaymentMethod()

	const isStartChargingSessionLoading =
		isStartChargingSessionPending || isStartChargingSessionSuccess

	const handleNextClick = useCallback((): void => {
		if (selectedPaymentMethod) {
			void startChargingSession(
				{
					...routeParams,
					paymentMethodUid: selectedPaymentMethod._id ?? ''
				},
				{
					onError: ({ response }): void => {
						const errorViewProps = ((): NoticeProps => {
							const errorCode = response?.data.error?.code
							switch (errorCode) {
								case OmniError.PaymentInsufficientFunds:
									return {
										type: 'warning',
										header: intl.formatMessage({
											id: 'PaymentMethodsView.TitleInsufficientFunds',
											defaultMessage: 'Your Account Has Insufficient Balance'
										})
									}
								default:
									return {
										type: 'error',
										header: intl.formatMessage({
											id: 'PaymentMethodsView.TitleStartChargeFailure',
											defaultMessage: 'Failed to Start Charge'
										}),
										description: intl.formatMessage({
											id: 'PaymentMethodsView.DescriptionStartChargeFailure',
											defaultMessage: 'Please try again later'
										})
									}
							}
						})()
						updateView?.(ChargerScreenViewItemKey.ErrorView, errorViewProps)
					},
					onSuccess: (): void => {
						const handleStartChargingSuccess = async (): Promise<void> => {
							await resetChargerSessionDetailsQueries()
							handleNext?.()
						}
						void handleStartChargingSuccess()
					}
				}
			)
		}
	}, [
		intl,
		routeParams,
		selectedPaymentMethod,
		handleNext,
		resetChargerSessionDetailsQueries,
		startChargingSession,
		updateView
	])

	const handlePaymentMethodClick = useCallback((paymentMethod: PaymentMethod): void => {
		setSelectedPaymentMethod(paymentMethod)
	}, [])

	const listItemRightRender = useCallback(
		(paymentMethod: PaymentMethod): JSX.Element => {
			const isExpiredPaymentMethod =
				!!paymentMethod.tokenExpiresAt && dayjs().isSameOrAfter(paymentMethod.tokenExpiresAt)

			const isDefaultPaymentMethod = paymentMethod._id === defaultPaymentMethod?._id

			const isSelectedPaymentMethod = paymentMethod._id === selectedPaymentMethod?._id

			return (
				<div className="flex items-center space-x-2">
					{isDefaultPaymentMethod && (
						<div className="flex items-center rounded-full border border-primary-800 bg-primary-800 bg-opacity-10 px-2 py-0.5">
							<span
								data-testid="cs-pmv-text-default-payment-method"
								className="caption-2-normal text-primary-800"
							>
								<FormattedMessage id="PaymentMethodsView.TagDefault" defaultMessage="Default" />
							</span>
						</div>
					)}
					{isExpiredPaymentMethod && (
						<div className="flex items-center rounded-full border border-error-300 bg-error-300 bg-opacity-10 px-2 py-0.5">
							<span
								data-testid={formatDataTestId([
									'cs-pmv-text-expired-payment-method',
									paymentMethod._id ?? ''
								])}
								className="caption-2-normal text-error-300"
							>
								<FormattedMessage id="PaymentMethodsView.TagExpired" defaultMessage="Expired" />
							</span>
						</div>
					)}
					{isSelectedPaymentMethod ? (
						<CheckCircleIcon
							className="w-5 text-success-400"
							data-testid="cs-pmv-icon-selected-payment-method"
						/>
					) : (
						<div
							className="h-5 w-5 rounded-full border border-divider-primary"
							data-testid={formatDataTestId([
								'cs-pmv-radio-select-payment-method',
								paymentMethod._id ?? ''
							])}
						/>
					)}
				</div>
			)
		},
		[defaultPaymentMethod, selectedPaymentMethod]
	)

	/**
	 * Initialise Default Payment Method
	 */
	useEffect((): void => {
		if (!defaultPaymentMethod && !selectedPaymentMethod && paymentMethods[0]) {
			// Currently assumes that we only have 1 payment method, hence it's also the default payment.
			// FUTURE TODO: Handle multiple payment methods.
			setDefaultPaymentMethod(paymentMethods[0])
			setSelectedPaymentMethod(paymentMethods[0])
		}
	}, [defaultPaymentMethod, paymentMethods, selectedPaymentMethod])

	useChargerDebugLogging({
		connector,
		isUserChargingSession,
		session,
		viewItemKey: ChargerScreenViewItemKey.PaymentMethodsView
	})

	return (
		<>
			<div
				className={classNames(
					'flex-grow',
					isPaymentMethodsLoading ? 'flex flex-col items-center justify-center pb-6' : null
				)}
			>
				{isPaymentMethodsLoading ? (
					<Spinner />
				) : (
					<>
						{/* Payment Methods List */}
						<PaymentMethodsList
							className="mb-5"
							dataTestIdPrefix="cs-pmv"
							paymentMethods={paymentMethods}
							listItemRightRender={listItemRightRender}
							onPaymentMethodClick={handlePaymentMethodClick}
						/>
						{/* Add/Replace Payment Method Button */}
						{((): ReactNode => {
							switch (BRAND) {
								// TNG eWallet
								case Brand.TouchNGo: {
									return (
										paymentMethods.length === 0 && (
											<Button
												data-testid="cs-pmv-btn-add-payment-method"
												variant="dashed"
												className="w-full"
												disabled={isTngSetupIntentLoading || isAddTngPaymentMethodPending}
												loading={isAddTngPaymentMethodPending}
												onClick={addTngPaymentMethod}
											>
												+{' '}
												<FormattedMessage
													id="PaymentMethodsView.ButtonTextAddPaymentMethod"
													defaultMessage="Add Payment Method"
												/>
											</Button>
										)
									)
								}
								// Card Payment Method
								default: {
									return (
										<Button
											data-testid="cs-pmv-btn-add-card"
											variant="dashed"
											className="w-full"
											disabled={isStartChargingSessionLoading}
											linkProps={{ to: ScreenRoutePath.AccountPaymentMethodsNew }}
										>
											+{' '}
											{paymentMethods.length === 0 ? (
												<FormattedMessage
													id="PaymentMethodsView.ButtonTextAddCard"
													defaultMessage="Add Card"
												/>
											) : (
												<FormattedMessage
													id="PaymentMethodsView.ButtonTextReplaceCard"
													defaultMessage="Replace Card"
												/>
											)}
										</Button>
									)
								}
							}
						})()}
					</>
				)}
			</div>
			<Footer
				className="space-y-6"
				buttonProps={{
					children: intl.formatMessage({
						id: 'PaymentMethodsView.ButtonTextNext',
						defaultMessage: 'Next'
					}),
					className: 'w-full',
					disabled: isStartChargingSessionLoading || !selectedPaymentMethod,
					loading: isStartChargingSessionLoading,
					onClick: handleNextClick
				}}
				bottomRender={
					<TermsAndConditionsBottomRender
						prefixRender={
							<FormattedMessage
								id="PaymentMethodsView.FooterTextTermsAndConditionsPrefix"
								defaultMessage='By clicking "Next", you agree to our '
							/>
						}
					/>
				}
			/>
		</>
	)
}

export default PaymentMethodsView
