import { memo, useMemo } from 'react'
import { COUNTRIES } from 'src/_shared/constants/country'
import { formatDataTestId } from 'src/_shared/utils/string'

import Select, { SelectOption, SelectProps } from '../Select'

type SelectCountryProps<T> = {
	optionsFormatter?: (option: (typeof COUNTRIES)[0]) => SelectOption<T>
	dataTestIdPrefix?: string
} & Omit<SelectProps<T>, 'options' | 'ref'>

const SelectCountry = <T extends string | number>({
	optionsFormatter,
	dataTestIdPrefix = '',
	...props
}: SelectCountryProps<T>): JSX.Element => {
	const options = useMemo((): SelectOption<T>[] | SelectOption<string>[] => {
		if (optionsFormatter) {
			return COUNTRIES.map(optionsFormatter)
		}
		return COUNTRIES.map(({ name, countryCode, emoji }): SelectOption<string> => {
			return {
				label: `${emoji} ${countryCode}`,
				dropdownLabel: `${emoji} ${countryCode} • ${name}`,
				value: countryCode
			}
		})
	}, [optionsFormatter])
	return (
		<Select
			dataTestIdPrefix={formatDataTestId([dataTestIdPrefix, 'country'])}
			{...props}
			options={options}
		/>
	)
}

const MemoisedSelectCountry = memo(SelectCountry)

export default MemoisedSelectCountry
