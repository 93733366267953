export enum OmniEvseCapability {
	ChargingProfileCapable = 'CHARGING_PROFILE_CAPABLE',
	ChipCardSupport = 'CHIP_CARD_SUPPORT',
	ContactlessCardSupport = 'CONTACTLESS_CARD_SUPPORT',
	CreditCardPayable = 'CREDIT_CARD_PAYABLE',
	DebitCardPayable = 'DEBIT_CARD_PAYABLE',
	PedTerminal = 'PED_TERMINAL',
	RemoteStartStopCapable = 'REMOTE_START_STOP_CAPABLE',
	Reservable = 'RESERVABLE',
	ReservationRequired = 'RESERVATION_REQUIRED',
	RfidReader = 'RFID_READER',
	StartSessionConnectorRequired = 'START_SESSION_CONNECTOR_REQUIRED',
	TimebeltReservable = 'TIMEBELT_RESERVABLE',
	TokenGroupCapable = 'TOKEN_GROUP_CAPABLE',
	UnlockCapable = 'UNLOCK_CAPABLE'
}
