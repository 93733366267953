import { DetailedHTMLProps, ImgHTMLAttributes, memo } from 'react'

import amexLogo from './amexLogo.png'

const AmexLogoImage = ({
	alt = 'American Express',
	...props
}: Omit<
	DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>,
	'src'
>): JSX.Element => {
	return <img alt={alt} src={amexLogo} {...props} />
}

const MemoisedAmexLogoImage = memo(AmexLogoImage)

export default MemoisedAmexLogoImage
