import { UseMutationOptions, useMutation } from '@tanstack/react-query'
import axios, { AxiosError, AxiosResponse } from 'axios'

import { BRAND, CPO_BACKEND_URL } from '../constants/env'
import { Brand } from '../enums/env'
import { useAuthContext } from '../hooks/useAuthContext'

interface UserTokenRefreshMutationParams {
	refreshToken: string
}

interface UserTokenRefreshData {
	token: string
}

export const useUserTokenRefreshMutation = (
	options?: Omit<
		UseMutationOptions<
			AxiosResponse<UserTokenRefreshData>,
			AxiosError<{ message: string }>,
			UserTokenRefreshMutationParams
		>,
		'mutationFn'
	>
) => {
	return useMutation({
		...options,
		mutationFn: async (
			params: UserTokenRefreshMutationParams
		): Promise<AxiosResponse<UserTokenRefreshData>> => {
			try {
				const response = await axios.post<UserTokenRefreshData>(
					`${CPO_BACKEND_URL}/v2/auth/refresh`,
					{},
					{
						headers: { Authorization: params.refreshToken }
					}
				)
				return response
			} catch (error) {
				const axiosError = error as AxiosError<{ message: string }>
				return Promise.reject(axiosError)
			}
		}
	})
}

interface UserLoginMutationParams {
	mobileNumber: string
	password: string
}

interface UserLoginData {
	token: string
	refreshToken: string
}

export const useUserLoginMutation = (
	options?: Omit<
		UseMutationOptions<UserLoginData, AxiosError<{ message: string }>, UserLoginMutationParams>,
		'mutationFn'
	>
) => {
	return useMutation({
		...options,
		mutationFn: async (params: UserLoginMutationParams) => {
			try {
				const response = await axios.post<UserLoginData>(`${CPO_BACKEND_URL}/v2/auth/login`, params)
				return response.data
			} catch (error) {
				const axiosError = error as AxiosError<{ message: string }>
				return Promise.reject(axiosError)
			}
		}
	})
}

interface UserSsoLoginMutationParams {
	ssoToken: string
}

interface UserSsoLoginData {
	token: string
	refreshToken: string
}

export const useUserSsoLoginMutation = (
	options?: Omit<
		UseMutationOptions<
			UserSsoLoginData,
			AxiosError<{ message: string }>,
			UserSsoLoginMutationParams
		>,
		'mutationFn'
	>
) => {
	return useMutation({
		...options,
		mutationFn: async ({ ssoToken }: UserSsoLoginMutationParams) => {
			try {
				const response = await axios.post<UserSsoLoginData>(
					`${CPO_BACKEND_URL}/v3/auth/${BRAND}/sso`,
					((): Record<string, string> => {
						switch (BRAND) {
							case Brand.Evme:
								return {
									customer_token: ssoToken
								}
							default:
								return {
									sso_token: ssoToken
								}
						}
					})()
				)
				return response.data
			} catch (error) {
				const axiosError = error as AxiosError<{ message: string }>
				return Promise.reject(axiosError)
			}
		}
	})
}

interface UserValidateEmailMutationParams {
	email: string
}

interface UserValidateEmailData {
	status: number
	message: string
}

export const useUserValidateEmailMutation = (
	options?: Omit<
		UseMutationOptions<
			UserValidateEmailData,
			AxiosError<UserValidateEmailData>,
			UserValidateEmailMutationParams
		>,
		'mutationFn'
	>
) => {
	return useMutation({
		...options,
		mutationFn: async (params: UserValidateEmailMutationParams) => {
			try {
				const response = await axios.post<UserValidateEmailData>(
					`${CPO_BACKEND_URL}/v3/auth/validate-email`,
					params
				)
				return response.data
			} catch (error) {
				const axiosError = error as AxiosError<UserValidateEmailData & { message: string }>
				return Promise.reject(axiosError)
			}
		}
	})
}

interface UserVerifyMobileNumberMutationParams {
	mobileNumber: string
}

interface UserVerifyMobileNumberData {
	hasUser: boolean
}

/**
 * Forgot Password Flow: Step 1
 */
export const useUserVerifyMobileNumberMutation = (
	options?: Omit<
		UseMutationOptions<
			UserVerifyMobileNumberData,
			AxiosError<{ message: string }>,
			UserVerifyMobileNumberMutationParams
		>,
		'mutationFn'
	>
) => {
	return useMutation({
		...options,
		mutationFn: async (params: UserVerifyMobileNumberMutationParams) => {
			try {
				const response = await axios.post<UserVerifyMobileNumberData>(
					`${CPO_BACKEND_URL}/v2/auth/verify-phone`,
					params
				)
				return response.data
			} catch (error) {
				const axiosError = error as AxiosError<{ message: string }>
				return Promise.reject(axiosError)
			}
		}
	})
}

interface UserRegistrationEligibilityMutationParams {
	mobileNumber: string
}

interface UserRegistrationEligibilityData {
	is_eligible: boolean
	status: number
}

/**
 * Registration Flow: Step 1
 */
export const useUserRegistrationEligibilityMutation = (
	options?: Omit<
		UseMutationOptions<
			UserRegistrationEligibilityData,
			AxiosError<UserRegistrationEligibilityData & { message: string }>,
			UserRegistrationEligibilityMutationParams
		>,
		'mutationFn'
	>
) => {
	return useMutation({
		...options,
		mutationFn: async (params: UserRegistrationEligibilityMutationParams) => {
			try {
				const response = await axios.post<UserRegistrationEligibilityData>(
					`${CPO_BACKEND_URL}/v2/auth/check-registration-eligibility`,
					params
				)
				return response.data
			} catch (error) {
				const axiosError = error as AxiosError<
					UserRegistrationEligibilityData & { message: string }
				>
				return Promise.reject(axiosError)
			}
		}
	})
}

interface UserOtpRequestMutationParams {
	mobileNumber: string
}

interface UserRegisterMutationParams {
	name: string
	mobileNumber: string
	password: string
	email: string
	licensePlateNumber?: string
	otp: string
}

interface UserRegisterData {
	token: string
	refreshToken: string
}

/**
 * Registration Flow: Step 2
 */
export const useUserRegisterMutation = (
	options?: Omit<
		UseMutationOptions<
			UserRegisterData,
			AxiosError<{ message: string }>,
			UserRegisterMutationParams
		>,
		'mutationFn'
	>
) => {
	return useMutation({
		...options,
		mutationFn: async (params: UserRegisterMutationParams) => {
			try {
				const { licensePlateNumber: vehiclePlateNumber, ...rest } = params
				const response = await axios.post<UserRegisterData>(`${CPO_BACKEND_URL}/v2/auth/register`, {
					...rest,
					vehiclePlateNumber
				})
				return response.data
			} catch (error) {
				const axiosError = error as AxiosError<{ message: string }>
				return Promise.reject(axiosError)
			}
		}
	})
}

/**
 * Forgot Password Flow: Step 1
 * Registration Flow: Step 2
 */
export const useUserOtpRequestMutation = (
	options?: Omit<
		UseMutationOptions<
			AxiosResponse<{ message: string }>,
			AxiosError<{ message: string }>,
			UserOtpRequestMutationParams
		>,
		'mutationFn'
	>
) => {
	return useMutation({
		...options,
		mutationFn: async (params: UserOtpRequestMutationParams) => {
			try {
				const { mobileNumber } = params
				const response = await axios.post<{ message: string }>(`${CPO_BACKEND_URL}/v2/auth/otp`, {
					mobileNumber
				})
				return response
			} catch (error) {
				const axiosError = error as AxiosError<{ message: string }>
				return Promise.reject(axiosError)
			}
		}
	})
}

interface UserOtpVerifyMutationParams {
	mobileNumber: string
	otp: string
}

interface UserOtpVerifyData {
	expires_at: number
	token: string
}

/**
 * Forgot Password Flow: Step 2
 */
export const useUserOtpVerifyMutation = (
	options?: Omit<
		UseMutationOptions<
			AxiosResponse<UserOtpVerifyData>,
			AxiosError<{ message: string }>,
			UserOtpVerifyMutationParams
		>,
		'mutationFn'
	>
) => {
	return useMutation({
		...options,
		mutationFn: async (params: UserOtpVerifyMutationParams) => {
			try {
				const { mobileNumber, otp } = params
				const response = await axios.post<UserOtpVerifyData>(
					`${CPO_BACKEND_URL}/v2/auth/reset-password`,
					{
						mobileNumber,
						otp
					}
				)
				return response
			} catch (error) {
				const axiosError = error as AxiosError<{ message: string }>
				return Promise.reject(axiosError)
			}
		}
	})
}

interface UserOtpResetPasswordMutationParams {
	mobileNumber: string
	password: string
	resetToken: string
}

/**
 * Forgot Password Flow: Step 3
 */
export const useUserOtpResetPasswordMutation = (
	options?: Omit<
		UseMutationOptions<
			AxiosResponse<{ message: string }>,
			AxiosError<{ message: string }>,
			UserOtpResetPasswordMutationParams
		>,
		'mutationFn'
	>
) => {
	return useMutation({
		...options,
		mutationFn: async (params: UserOtpResetPasswordMutationParams) => {
			try {
				const { mobileNumber, password, resetToken: token } = params
				const response = await axios.post<{ message: string }>(
					`${CPO_BACKEND_URL}/v2/auth/confirm-reset-password`,
					{
						mobileNumber,
						password,
						token
					}
				)
				return response
			} catch (error) {
				const axiosError = error as AxiosError<{ message: string }>
				return Promise.reject(axiosError)
			}
		}
	})
}

interface UserResetPasswordMutationParams {
	oldPassword: string
	newPassword: string
}

/**
 * Change Password
 */
export const useUserResetPasswordMutation = (
	options?: Omit<
		UseMutationOptions<
			AxiosResponse<{ message: string }>,
			AxiosError<{ message: string }>,
			UserResetPasswordMutationParams
		>,
		'mutationFn'
	>
) => {
	const { accessToken } = useAuthContext()
	return useMutation({
		...options,
		mutationFn: async (params: UserResetPasswordMutationParams) => {
			try {
				const { oldPassword: old_password, newPassword: new_password } = params
				const response = await axios.post<{ message: string }>(
					`${CPO_BACKEND_URL}/v3/auth/reset-password`,
					{
						old_password,
						new_password
					},
					{
						headers: { Authorization: accessToken }
					}
				)
				return response
			} catch (error) {
				const axiosError = error as AxiosError<{ message: string }>
				return Promise.reject(axiosError)
			}
		}
	})
}
