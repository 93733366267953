import dayjs from 'dayjs'
import { Fragment, ReactNode } from 'react'
import { FormattedMessage } from 'react-intl'
import CardIcon from 'src/_shared/components/_icons/CardIcon'
import { PaymentMethodCardType, PaymentMethodCode } from 'src/_shared/enums/payments'
import { PaymentMethod } from 'src/_shared/types/payments'
import { classNames } from 'src/_shared/utils/elements'
import { formatDataTestId } from 'src/_shared/utils/string'

import AmexLogoImage from '../_images/AmexLogoImage'
import GrabPayStackedLogoImage from '../_images/GrabPayStackedLogoImage'
import JcbLogoImage from '../_images/JcbLogoImage'
import MastercardLogoImage from '../_images/MastercardLogoImage'
import TouchNGoLogoImage from '../_images/TouchNGoLogoImage'
import UnionPayLogoImage from '../_images/UnionPayLogoImage'
import VisaAndMastercardStackedLogosImage from '../_images/VisaAndMastercardStackedLogosImage'
import VisaLogoImage from '../_images/VisaLogoImage'

interface PaymentMethodsListProps {
	paymentMethods: PaymentMethod[]
	className?: string
	dataTestIdPrefix?: string
	listItemLeftRender?: (paymentMethod: PaymentMethod, index: number) => ReactNode
	listItemRightRender?: (paymentMethod: PaymentMethod, index: number) => ReactNode
	onPaymentMethodClick?: (paymentMethod: PaymentMethod, index: number) => void
}

const PaymentMethodsList = ({
	paymentMethods,
	className,
	dataTestIdPrefix = '',
	listItemLeftRender,
	listItemRightRender,
	onPaymentMethodClick: handlePaymentMethodClick
}: PaymentMethodsListProps): JSX.Element => {
	return (
		<div
			className={className}
			data-testid={formatDataTestId([dataTestIdPrefix, 'payment-methods-list'])}
		>
			{paymentMethods.length > 0 ? (
				paymentMethods.map((paymentMethod, index): JSX.Element => {
					/**
					 * Default Render: Payment Method Icon with Partial Details
					 */
					const leftElement = ((): ReactNode => {
						if (listItemLeftRender) {
							return listItemLeftRender(paymentMethod, index)
						}
						const isExpiredPaymentMethod =
							!!paymentMethod.tokenExpiresAt && dayjs().isSameOrAfter(paymentMethod.tokenExpiresAt)

						const { cardType, lastFourDigits = '', methodCode } = paymentMethod

						const { paymentMethodIcon, paymentMethodName } = ((): {
							paymentMethodIcon: ReactNode
							paymentMethodName: string
						} => {
							// Payment Methods with Card Number
							const iconClassName = classNames(
								'h-6 w-auto',
								isExpiredPaymentMethod ? 'opacity-25' : null
							)
							if (cardType) {
								const cardNumber = lastFourDigits ? `**** ${lastFourDigits}` : null
								switch (cardType) {
									case PaymentMethodCardType.Amex:
										return {
											paymentMethodIcon: <AmexLogoImage className={iconClassName} />,
											paymentMethodName: cardNumber ?? 'Amex'
										}
									case PaymentMethodCardType.Jcb:
										return {
											paymentMethodIcon: <JcbLogoImage className={iconClassName} />,
											paymentMethodName: cardNumber ?? 'JCB'
										}
									case PaymentMethodCardType.Mastercard:
										return {
											paymentMethodIcon: <MastercardLogoImage className={iconClassName} />,
											paymentMethodName: cardNumber ?? 'Mastercard'
										}
									case PaymentMethodCardType.UnionPay:
										return {
											paymentMethodIcon: <UnionPayLogoImage className={iconClassName} />,
											paymentMethodName: cardNumber ?? 'UnionPay'
										}
									case PaymentMethodCardType.Visa:
										return {
											paymentMethodIcon: <VisaLogoImage className={iconClassName} />,
											paymentMethodName: cardNumber ?? 'Visa'
										}
								}
							} else if (methodCode) {
								switch (methodCode) {
									case PaymentMethodCode.Emv:
										return {
											paymentMethodIcon: (
												<VisaAndMastercardStackedLogosImage className={iconClassName} />
											),
											paymentMethodName: 'Card'
										}
									case PaymentMethodCode.GrabPay:
										return {
											paymentMethodIcon: <GrabPayStackedLogoImage className={iconClassName} />,
											paymentMethodName: 'GrabPay'
										}
									case PaymentMethodCode.TouchNGo:
										return {
											paymentMethodIcon: <TouchNGoLogoImage className={iconClassName} />,
											paymentMethodName: 'TnG Wallet'
										}
								}
							}
							return {
								paymentMethodIcon: (
									<CardIcon
										className={classNames(
											'max-h-6 max-w-9',
											isExpiredPaymentMethod ? 'opacity-25' : null
										)}
									/>
								),
								paymentMethodName: 'Unknown'
							}
						})()

						return (
							<div className="flex items-center space-x-3">
								<div className="flex w-11 items-center justify-center">{paymentMethodIcon}</div>
								<div>
									<p
										className={classNames(
											'body-2-normal',
											isExpiredPaymentMethod ? 'text-grayscale-600' : 'text-typography-primary'
										)}
									>
										{paymentMethodName}
									</p>
								</div>
							</div>
						)
					})()

					/**
					 * Default Render: Empty
					 */
					const rightElement = ((): ReactNode => {
						if (listItemRightRender) {
							return listItemRightRender(paymentMethod, index)
						}
						return null
					})()

					/**
					 * Payment Method Item
					 */
					const innerElement = (
						<div className="flex items-center justify-between px-1 py-3">
							{leftElement}
							{rightElement}
						</div>
					)

					return (
						<Fragment key={index}>
							{handlePaymentMethodClick ? (
								<button
									className="w-full"
									data-testid={formatDataTestId([
										dataTestIdPrefix,
										'btn-payment-method',
										paymentMethod._id ?? ''
									])}
									onClick={(): void => {
										handlePaymentMethodClick(paymentMethod, index)
									}}
								>
									{innerElement}
								</button>
							) : (
								<div
									data-testid={formatDataTestId([
										dataTestIdPrefix,
										'payment-method',
										paymentMethod._id ?? ''
									])}
								>
									{innerElement}
								</div>
							)}

							{/* Divider */}
							{index < paymentMethods.length - 1 && (
								<div className="my-2 border-b border-divider-primary" />
							)}
						</Fragment>
					)
				})
			) : (
				<span className="body-1-normal text-typography-tertiary">
					<FormattedMessage
						id="PaymentMethodsList.FooterTextNoPaymentMethod"
						defaultMessage="There is no payment method to select. Please add a payment method to proceed for charging."
					/>
				</span>
			)}
		</div>
	)
}

export default PaymentMethodsList
