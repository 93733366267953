import { ReactNode, memo } from 'react'
import { classNames } from 'src/_shared/utils/elements'
import { formatDataTestId } from 'src/_shared/utils/string'

export interface LabelValueListItemProps {
	containerClassName?: string | null
	label: string
	labelClassName?: string | null
	value: ReactNode
	valueClassName?: string | null
}

const LabelValueListItem = ({
	containerClassName,
	label,
	labelClassName,
	value,
	valueClassName
}: LabelValueListItemProps) => {
	return (
		<div
			className={classNames(
				'flex items-center justify-between space-x-5 [&:not(:last-child)]:mb-3',
				containerClassName
			)}
		>
			{/* Left Label */}
			<div>
				<label
					data-testid={formatDataTestId(['rd-label', label.toLowerCase()])}
					className={classNames('body-2-normal text-typography-tertiary', labelClassName)}
				>
					{label}
				</label>
			</div>
			{/* Right Value */}
			<div className="min-w-20 max-w-[55%]">
				<p
					data-testid={formatDataTestId(['rd-text-value', label.toLowerCase()])}
					className={classNames(
						'body-2-semibold break-words text-right text-typography-primary',
						valueClassName
					)}
				>
					{value}
				</p>
			</div>
		</div>
	)
}

const MemoisedLabelValueListItem = memo(LabelValueListItem)

export default MemoisedLabelValueListItem
