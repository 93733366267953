import { SVGProps, memo } from 'react'

const ChargerAcIcon = ({
	fill = 'none',
	stroke = 'currentColor',
	viewBox = '0 0 29 26',
	xmlns = 'http://www.w3.org/2000/svg',
	...props
}: SVGProps<SVGSVGElement>) => {
	return (
		<svg fill={fill} stroke={stroke} viewBox={viewBox} xmlns={xmlns} {...props}>
			<path d="M17.9469 19.3874C19.1908 19.3874 20.1991 18.4029 20.1991 17.1883C20.1991 15.9738 19.1908 14.9893 17.9469 14.9893C16.7031 14.9893 15.6948 15.9738 15.6948 17.1883C15.6948 18.4029 16.7031 19.3874 17.9469 19.3874Z" />
			<path d="M10.7348 19.3874C11.9786 19.3874 12.9869 18.4029 12.9869 17.1883C12.9869 15.9738 11.9786 14.9893 10.7348 14.9893C9.49097 14.9893 8.48267 15.9738 8.48267 17.1883C8.48267 18.4029 9.49097 19.3874 10.7348 19.3874Z" />
			<path d="M7.34099 13.4485C8.5848 13.4485 9.59311 12.4639 9.59311 11.2494C9.59311 10.0349 8.5848 9.05029 7.34099 9.05029C6.09718 9.05029 5.08887 10.0349 5.08887 11.2494C5.08887 12.4639 6.09718 13.4485 7.34099 13.4485Z" />
			<path d="M14.3398 13.4485C15.5836 13.4485 16.5919 12.4639 16.5919 11.2494C16.5919 10.0349 15.5836 9.05029 14.3398 9.05029C13.096 9.05029 12.0876 10.0349 12.0876 11.2494C12.0876 12.4639 13.096 13.4485 14.3398 13.4485Z" />
			<path d="M21.2353 13.4485C22.4791 13.4485 23.4874 12.4639 23.4874 11.2494C23.4874 10.0349 22.4791 9.05029 21.2353 9.05029C19.9915 9.05029 18.9832 10.0349 18.9832 11.2494C18.9832 12.4639 19.9915 13.4485 21.2353 13.4485Z" />
			<path d="M17.8961 8.12094C18.8042 8.12094 19.5403 7.36108 19.5403 6.42375C19.5403 5.48642 18.8042 4.72656 17.8961 4.72656C16.9881 4.72656 16.252 5.48642 16.252 6.42375C16.252 7.36108 16.9881 8.12094 17.8961 8.12094Z" />
			<path d="M10.9984 8.12094C11.9065 8.12094 12.6426 7.36108 12.6426 6.42375C12.6426 5.48642 11.9065 4.72656 10.9984 4.72656C10.0904 4.72656 9.35425 5.48642 9.35425 6.42375C9.35425 7.36108 10.0904 8.12094 10.9984 8.12094Z" />
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				strokeWidth="2"
				strokeLinejoin="round"
				d="M7.23488 1.43945H21.6582C21.6582 1.43945 23.1706 2.46211 23.8077 3.16479C24.4449 3.86747 25.0518 4.81225 25.4761 5.57553C25.9004 6.3388 26.2441 7.02247 26.5367 7.90873C26.8292 8.79499 27.0974 9.7184 27.173 10.8782C27.2486 12.0381 27.1387 13.6251 26.8548 14.8022C26.571 15.9794 26.0437 17.0506 25.5822 17.8778C25.1207 18.7049 24.7826 19.1241 24.2035 19.7867C23.6243 20.4494 22.8633 21.1967 22.0824 21.8018C21.3015 22.4068 20.4798 22.9605 19.5371 23.3926C18.5944 23.8246 16.4616 24.3471 16.4616 24.3471C16.4616 24.3471 13.5734 24.7344 11.2649 23.9228C10.3473 23.6002 9.74605 23.4903 8.71963 22.9684C7.43383 22.3145 6.19543 21.4856 5.21986 20.4231C3.87907 18.9627 2.80451 17.2018 2.1443 15.3325C1.62151 13.8522 1.45031 12.2349 1.50798 10.6661C1.55179 9.47438 1.83053 8.28256 2.25036 7.16635C2.60695 6.21828 3.21204 5.30633 3.73511 4.51501C4.24679 3.74091 5.14777 2.89229 5.75013 2.39394C6.35249 1.89559 7.23488 1.43945 7.23488 1.43945Z"
			/>
		</svg>
	)
}

const MemoisedChargerAcIcon = memo(ChargerAcIcon)

export default MemoisedChargerAcIcon
