import { DetailedHTMLProps, ImgHTMLAttributes, memo } from 'react'

import mastercardLogo from './mastercardLogo.png'

const MastercardLogoImage = ({
	alt = 'Mastercard',
	...props
}: Omit<
	DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>,
	'src'
>): JSX.Element => {
	return <img alt={alt} src={mastercardLogo} {...props} />
}

const MemoisedMastercardLogoImage = memo(MastercardLogoImage)

export default MemoisedMastercardLogoImage
