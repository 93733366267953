import { BsTriangleFill } from 'react-icons/bs'
import Flash from 'src/_shared/_old/assets/svgs/Flash'
import { LAST_APPLIED_FILTERS_KEY } from 'src/_shared/_old/enums.ts'
import { Filter, OmniLocation } from 'src/_shared/_old/schemas/typings'
import { handleCheckAvailability } from 'src/_shared/_old/utils/charging'
import { filterEvse } from 'src/_shared/_old/utils/filter.tsx'

interface MarkerProps {
	onClick: (location: OmniLocation) => void
	location: OmniLocation
	lat?: number
	lng?: number
	hide: boolean
}

const MARKER_Z_INDEX = 10

const Marker = ({ onClick, location, hide }: MarkerProps) => {
	const lastAppliedFilters = JSON.parse(
		sessionStorage.getItem(LAST_APPLIED_FILTERS_KEY) ?? 'null'
	) as Filter | null

	const lastAppliedPowerTypeFilter = lastAppliedFilters?.powerTypeFilter ?? null

	const hasAvailable = filterEvse(location.evses, lastAppliedPowerTypeFilter).some((evse) =>
		handleCheckAvailability(evse)
	)

	return !hide ? (
		<button
			data-testid={hasAvailable ? 'marker-avail' : 'marker-unavail'}
			className="absolute flex -translate-x-1/2 -translate-y-full transform flex-col items-center justify-center outline-none drop-shadow-lg focus:outline-none active:outline-none"
			ref={(buttonRef) => {
				if (buttonRef?.parentElement) {
					const parentDivElement = buttonRef.parentElement as HTMLDivElement
					parentDivElement.style.zIndex = String(MARKER_Z_INDEX)
				}
			}}
			onClick={() => {
				onClick(location)
			}}
		>
			<div
				className={`flex flex-row items-center justify-center rounded-lg px-2 py-2
            ${hasAvailable ? 'bg-success-400' : 'bg-error-300'} `}
			>
				<Flash className="h-4 w-4 text-white" />
			</div>
			<div className="mt-[-2px] rotate-180">
				<BsTriangleFill
					className={`h-3 w-auto
            ${hasAvailable ? 'text-success-400' : 'text-error-300'} `}
				/>
			</div>
		</button>
	) : null
}

export default Marker
