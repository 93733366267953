import { createContext, useContext } from 'react'

import { UserInfo } from '../types/user'

interface AuthContextData {
	accessToken: string | null
	isAuthenticated: boolean
	user: UserInfo | null
	clearAuthTokens: () => void
	setAuthTokens: (
		newAccessToken: string,
		newRefreshToken: string,
		storageType?: 'local' | 'session'
	) => void
}

export const AuthContext = createContext<AuthContextData>({
	accessToken: null,
	isAuthenticated: false,
	user: null,
	clearAuthTokens: (): void => {
		console.debug('[AuthWrapper] clearAuthTokens was not provided.')
	},
	setAuthTokens: (): void => {
		console.debug('[AuthWrapper] setAuthTokens was not provided.')
	}
})

export const useAuthContext = (): AuthContextData => {
	const context = useContext(AuthContext)
	return context
}
