import { OmniConnector, OmniEvse } from '../types/omni'

interface MatchedChargers {
	/**
	 * The matched EVSEs that contain only the connectors that are matching based on the provided `qr_identifier`.
	 */
	matchedEvses: OmniEvse[]
	/**
	 * The matched connectors based on the provided `qr_identifier`.
	 */
	matchedConnectors: OmniConnector[]
}

/**
 * Returns the list of EVSEs and connectors that tally with the provided `qrIdentifier`.
 * @param {OmniEvse[]} evses The list of EVSEs to check against.
 * @param {string} qrIdentifier The string to match against each connector's `qr_identifier`.
 * @returns {MatchedChargers} The matching EVSEs and connectors with the specified `qrIdentifier`.
 */
export const getMatchingChargersByQrIdentifier = (
	evses: OmniEvse[] = [],
	qrIdentifier: string
): MatchedChargers => {
	const matchedEvses = evses
		.filter((evse): boolean => {
			return (
				evse.connectors?.some((connector): boolean => {
					return connector.qr_identifier === qrIdentifier
				}) ?? false
			)
		})
		.map((evse): OmniEvse => {
			return {
				...evse,
				connectors: (evse.connectors ?? []).filter((connector): boolean => {
					return connector.qr_identifier === qrIdentifier
				})
			}
		})

	const matchedConnectors = matchedEvses.reduce<OmniConnector[]>((connectors, evse) => {
		return connectors.concat(...(evse.connectors ?? []))
	}, [])

	return { matchedEvses, matchedConnectors }
}
