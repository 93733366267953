import { UseQueryOptions, useQuery } from '@tanstack/react-query'
import axios, { AxiosError } from 'axios'

import { CPO_BACKEND_URL } from '../constants/env'
import { useAuthContext } from '../hooks/useAuthContext'
import { OmniSession } from '../types/omni'
import { UserInfo } from '../types/user'

export const ROOT_USER_QUERY_KEY = 'User'

export enum UserQueryKey {
	UserInfo = 'UserInfo',
	UserSessionLatest = 'UserSessionLatest'
}

interface UserInfoQueryParams {
	accessToken: string
}

export const useUserInfoQuery = <TData = UserInfo>(
	params: UserInfoQueryParams,
	options?: Omit<
		UseQueryOptions<
			UserInfo,
			AxiosError<{ message: string }>,
			TData,
			[string, UserQueryKey.UserInfo, UserInfoQueryParams]
		>,
		'queryFn' | 'queryKey'
	>
) => {
	return useQuery({
		...options,
		queryKey: [ROOT_USER_QUERY_KEY, UserQueryKey.UserInfo, params],
		queryFn: async (): Promise<UserInfo> => {
			try {
				const response = await axios.get<UserInfo>(`${CPO_BACKEND_URL}/v2/me`, {
					headers: {
						Authorization: params.accessToken
					}
				})
				return response.data
			} catch (error) {
				const axiosError = error as AxiosError<{ message: string }>
				return Promise.reject(axiosError)
			}
		}
	})
}

interface UserSessionLatestQueryParams {
	/**
	 * If `true`, this forces a refresh of the current charger's status from the CPO CMS.
	 * This would cause the request to be slower, but it should guarantee the return the latest data.
	 */
	isRealTime?: boolean
}

interface UserSessionLatestResponse {
	data: {
		session: OmniSession | null
	} | null
	message: string
	status: number
}

export const useUserSessionLatestQuery = <TData = OmniSession | null>(
	params: UserSessionLatestQueryParams,
	options?: Omit<
		UseQueryOptions<
			OmniSession | null,
			AxiosError<UserSessionLatestResponse>,
			TData,
			[string, UserQueryKey.UserSessionLatest, string | null]
		>,
		'queryFn' | 'queryKey'
	>
) => {
	const { accessToken, isAuthenticated } = useAuthContext()
	return useQuery({
		...options,
		queryKey: [ROOT_USER_QUERY_KEY, UserQueryKey.UserSessionLatest, accessToken],
		enabled: (options?.enabled === undefined || options.enabled) && isAuthenticated,
		queryFn: async (): Promise<OmniSession | null> => {
			try {
				const { isRealTime = false } = params
				const response = await axios.get<UserSessionLatestResponse>(
					`${CPO_BACKEND_URL}/v2/me/sessions/latest`,
					{
						headers: { Authorization: accessToken },
						params: {
							is_real_time: isRealTime
						}
					}
				)
				return response.data.data?.session ?? null
			} catch (error) {
				const axiosError = error as AxiosError<UserSessionLatestResponse>
				if (axiosError.response?.status === 404 && axiosError.response.data.data === null) {
					return null
				}
				return Promise.reject(axiosError)
			}
		}
	})
}
