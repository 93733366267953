import { useIntl } from 'react-intl'
import ReceiptDetails from 'src/_shared/components/ReceiptDetails'

import { ChargerScreenViewItemKey } from '../enums'
import useChargerDebugLogging from '../hooks/useChargerDebugLogging'
import { useChargerDetails } from '../hooks/useChargerDetails'
import { useChargerSessionDetails } from '../hooks/useChargerSessionDetails'
import { ChargerScreenCommonViewProps } from '../types'
import Footer from './Footer'

type ReceiptViewProps = ChargerScreenCommonViewProps

const ReceiptView = ({ routeParams, onNext: handleNext }: ReceiptViewProps): JSX.Element => {
	const intl = useIntl()

	const { connector } = useChargerDetails(routeParams)

	const { session, isUserChargingSession, chargerSessionDetailsQueryStatus } =
		useChargerSessionDetails(routeParams)

	const isChargerSessionDetailsPending = chargerSessionDetailsQueryStatus === 'pending'

	useChargerDebugLogging({
		connector,
		isUserChargingSession,
		session,
		viewItemKey: ChargerScreenViewItemKey.ReceiptView
	})

	return (
		<>
			<div className="flex-grow">{session && <ReceiptDetails chargingSession={session} />}</div>
			<Footer
				buttonProps={{
					children: intl.formatMessage({
						id: 'ReceiptView.ButtonTextClose',
						defaultMessage: 'Close'
					}),
					className: 'w-full',
					disabled: isChargerSessionDetailsPending,
					variant: 'ghost',
					onClick: handleNext
				}}
			/>
		</>
	)
}

export default ReceiptView
