import { DetailedHTMLProps, ImgHTMLAttributes, memo } from 'react'

import plugitLogoBlue from './plugitLogoBlue.png'
import plugitLogoWhite from './plugitLogoWhite.png'

type PlugitLogoImageProps = Omit<
	DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>,
	'src'
> & {
	variant?: 'blue' | 'white'
}

const PlugitLogoImage = ({
	alt = 'Plugit',
	variant = 'blue',
	...props
}: PlugitLogoImageProps): JSX.Element => {
	const imageSrc = variant === 'blue' ? plugitLogoBlue : plugitLogoWhite
	return <img alt={alt} src={imageSrc} {...props} />
}

const MemoisedPlugitLogoImage = memo(PlugitLogoImage)

export default MemoisedPlugitLogoImage
