import { domAnimation, LazyMotion } from 'framer-motion'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { ScreenRoutePath, useRouterLocation, useRouterNavigate } from 'src/App/router/hooks'
import VoltalityWatermark from 'src/_shared/_old/assets/svgs/voltality_watermark.svg'
import LiveSession from 'src/_shared/components/LiveSession'
import { HIDE_UNSORTED_CPOS } from 'src/_shared/constants/env'
import { useAuthContext } from 'src/_shared/hooks/useAuthContext'
import useLiveSession from 'src/_shared/hooks/useLiveSession'
import useLocationPermissions from 'src/_shared/hooks/useLocationPermissions'
import { useStrapiContext } from 'src/_shared/hooks/useStrapiContext'
import { useLocationsQuery } from 'src/_shared/queries/locations'

import MapBottomContainer from '../../../_shared/_old/components/containers/MapBottomContainer'
import PageContainer from '../../../_shared/_old/components/containers/PageContainer'
import Filters from '../../../_shared/_old/components/map/Filters'
import Map from '../../../_shared/_old/components/map/Map'
import MapButtons from '../../../_shared/_old/components/map/MapButtons'
import CarparkRatesModal from '../../../_shared/_old/components/modals/CarparkRatesModal'
import Modal from '../../../_shared/_old/components/modals/Modal'
import Bottombar from '../../../_shared/_old/components/nav/Bottombar'
import Topbar from '../../../_shared/_old/components/nav/Topbar'
import Alert from '../../../_shared/_old/components/popups/Alert'
import DefaultMapPopup from '../../../_shared/_old/components/popups/DefaultMapPopup'
import FilterPopup from '../../../_shared/_old/components/popups/FilterPopup'
import LocationDetailsPopup from '../../../_shared/_old/components/popups/LocationDetailsPopup'
import {
	BOTTOMBAR_HEIGHT,
	FILTER_SETTINGS,
	FilterType,
	LAST_APPLIED_FILTERS_KEY,
	PowerType,
	TOPBAR_HEIGHT
} from '../../../_shared/_old/enums'
import useCurrentCoords from '../../../_shared/_old/hooks/useCurrentCoords'
import { Coordinates } from '../../../_shared/_old/schemas/geolocation'
import { CpoLocationPair, Filter, OmniLocation } from '../../../_shared/_old/schemas/typings'
import { getCarparkRates } from '../../../_shared/_old/utils/charging'
import { filterLocationCpos, filterLocations } from '../../../_shared/_old/utils/filter'
import { formatLocCoords } from '../../../_shared/_old/utils/format'
import { latLng2Point, miniPanTo, point2LatLng } from '../../../_shared/_old/utils/map'
import ListViewScreen from './ListViewScreen'
import SearchChargerScreen from './SearchChargerScreen'

type DisplayOrderMapping = Record<string, number>

const INITIAL_FILTER_STATE: Filter = {
	distLowest: FILTER_SETTINGS.minDistance,
	distHighest: FILTER_SETTINGS.maxDistance,
	cpoFilter: [],
	powerTypeFilter: null,
	isSubscribedFilter: null
}

export const LAST_LOCATION_DROPPED_PIN_NAME = 'searchLocationName'

// configure this if you would like to change the results shown in listView based on distance
const CLOSE_RANGE_DIST = 1.2

const hideUnsortedCpos = HIDE_UNSORTED_CPOS

export default function MapScreen() {
	const navigate = useRouterNavigate()
	const { isAuthenticated, user } = useAuthContext()
	const { subscribedCpoEntities } = user ?? {}
	const { hasLocationPermissions } = useLocationPermissions()
	const { currentCoords, isCurrentCoordsAvailable } = useCurrentCoords()

	// toggle whether SearchChargerScreen is shown
	const [showSearchChargerScreen, setShowSearchChargerScreen] = useState(false)

	// back from charging screens
	const location = useRouterLocation<{ location: OmniLocation } | undefined>()
	const state = location.state

	// location details
	const locDetailsRef = useRef<HTMLDivElement | null>(null)
	const [defaultPopupHeight, setDefaultPopupHeight] = useState<number>(0)
	const [showCarparkRates, setShowCarparkRates] = useState<boolean>(false)
	const [selectedLocation, setSelectedLocation] = useState<OmniLocation | null>(null)

	// google map & map interactions
	const mapRef = useRef<google.maps.Map | null>(null)
	const mapsRef = useRef<typeof google.maps | null>(null)
	const [zoom, setZoom] = useState<number>(12)
	const [center, setCenter] = useState<Coordinates | null>(null)
	const [centerOffset, setCenterOffset] = useState<number>(BOTTOMBAR_HEIGHT)
	const [mapType, setMapType] = useState<number>(0)

	// search function
	const [locationName, setLocationName] = useState<string | null>(
		localStorage.getItem(LAST_LOCATION_DROPPED_PIN_NAME) ?? null
	)
	const [showListScreen, setShowListScreen] = useState<boolean>(false)
	const [showSearchScreen, setShowSearchScreen] = useState<boolean>(false)
	const [searchedLocCoords, setSearchedLocCoords] = useState<Coordinates | null>(null)

	// filters
	const [filterType, setFilterType] = useState<FilterType | PowerType>()
	const [showFilterPopup, setShowFilterPopup] = useState(false)
	const [filters, setFilters] = useState<Filter>(INITIAL_FILTER_STATE)

	// live session
	const { chargerDetails: targetChargerDetails } = useLiveSession()

	// other states
	const [permisText, setPermisText] = useState<string>('')
	const [stopTouchProp, setStopTouchProp] = useState<boolean>(false)
	const [showPermisModal, setShowPermisModal] = useState<boolean>(false)

	// get all charging locations
	const { data: locations = [], isLoading: locationsLoading } = useLocationsQuery<OmniLocation[]>(
		{ fetchAll: true },
		{
			refetchInterval: 60000 // 1 Minute
		}
	)

	const { brandData } = useStrapiContext()

	// unless locations change, we shouldn't preprocess the data too frequently
	const cpoLocationMap: CpoLocationPair[] = useMemo(() => {
		const collatedKVPairs: CpoLocationPair[] = []
		locations.forEach((location: OmniLocation) => {
			const operatorName = location.operator.name
			// check whether has the pair been added
			const pairIndex = collatedKVPairs.findIndex((pair) => pair.cpoName === operatorName)
			if (pairIndex !== -1) {
				collatedKVPairs[pairIndex].cpoLocations.push(location)
			} else {
				collatedKVPairs.push({
					cpoName: operatorName,
					cpoLocations: [location],
					cpoEntityCode: location.entity_code
				})
			}
		})
		return collatedKVPairs
	}, [locations])

	// function to find OmniLocations specific to the CPO and filter locations that do not contain any evses
	const cpoLocationHandler = (
		cpoLocationMap: CpoLocationPair[],
		cpoName: string
	): CpoLocationPair => {
		const specificCpoLocations = cpoLocationMap.find(
			(cpoLocation) => cpoLocation.cpoName === cpoName
		)
		return {
			cpoName,
			cpoLocations: specificCpoLocations?.cpoLocations.filter((loc) => loc.evses.length > 0) ?? [],
			cpoEntityCode: specificCpoLocations?.cpoEntityCode ?? ''
		}
	}

	// [MapView and ListView] first filtering: filter locations based on filters
	const filteredLocations: OmniLocation[] = useMemo(() => {
		const isEmpty = Object.values(filters).every((filter) => filter === null)
		if (isEmpty) {
			return locations
		}

		return locations.filter((location: OmniLocation) =>
			filterLocations(
				location,
				filters,
				searchedLocCoords ?? currentCoords,
				subscribedCpoEntities ?? []
			)
		)
	}, [locations, filters, searchedLocCoords, currentCoords, subscribedCpoEntities])

	// [ListView] second filtering: filter locations based on closeness
	const filterLocationsByDistance: OmniLocation[] = useMemo(() => {
		if (filteredLocations.length === 0) {
			// initial filtered locations is already empty
			return filteredLocations
		} else {
			const closeRangeFilter: Filter = {
				...INITIAL_FILTER_STATE,
				distHighest: CLOSE_RANGE_DIST
			}
			const coords: Coordinates = searchedLocCoords ?? currentCoords
			return filteredLocations.filter((location: OmniLocation) =>
				filterLocationCpos(location, closeRangeFilter, coords)
			)
		}
	}, [filteredLocations, currentCoords, searchedLocCoords])

	function generateCpoSortOrderMap(cpoSortOrder: string[]): DisplayOrderMapping {
		const mapping: DisplayOrderMapping = {}

		cpoSortOrder.forEach((orgId, index) => {
			// Assign display order based on the index, starting from 1
			mapping[orgId] = index + 1
		})

		return mapping
	}

	const displayOrderMapping = generateCpoSortOrderMap(
		brandData?.attributes.configuration?.cpoSortOrder ?? []
	)

	const cpoListMemo = useMemo(() => {
		if (locations.length) {
			return locations
				.filter((location: OmniLocation) =>
					filterLocationCpos(location, filters, searchedLocCoords ?? currentCoords)
				)
				.reduce((acc: string[], location: OmniLocation) => {
					const operatorName = location.operator.name
					if (operatorName && !acc.includes(operatorName)) {
						if (hideUnsortedCpos && !displayOrderMapping[operatorName]) {
							// only show CPOs included in the sorted list
							return acc
						}
						return [...acc, operatorName]
					}
					return acc
				}, [])
				.sort((a: string, b: string) => {
					const orderA = displayOrderMapping[a]
					const orderB = displayOrderMapping[b]

					// Check if either organization is missing from the mapping
					if (!orderA && !orderB) return 0 // Both missing, keep original order
					if (!orderA) return 1 // A missing, sort to end
					if (!orderB) return -1 // B missing, sort to end

					// Both present, sort by display order
					return orderA - orderB
				})
		}
		return []
	}, [displayOrderMapping, locations, currentCoords, filters, searchedLocCoords])

	useEffect(() => {
		/* potential errors:
    1) string after /search might not contain '-' for splitting
    2) string array after split might not be of length 2
    3) each substring might not contain pure numbers
  */
		const convertSearchCoords = (urlSearchCoords: string): Coordinates => {
			const endpointOnly: string = urlSearchCoords.split('/search/')[1]
			const validationErr: Error = new Error('Invalid Coordinates URL')
			// checks condition 1
			if (endpointOnly.includes('-')) {
				// checks condition 2
				if (endpointOnly.split('-').length === 2) {
					const containsDigitsRegex = /^[0-9]+(\.[0-9]+)?$/
					// checks condition 3
					if (
						containsDigitsRegex.test(endpointOnly.split('-')[0]) &&
						containsDigitsRegex.test(endpointOnly.split('-')[1])
					) {
						return {
							lat: parseFloat(endpointOnly.split('-')[0]),
							lng: parseFloat(endpointOnly.split('-')[1])
						}
					} else {
						throw validationErr
					}
				} else {
					throw validationErr
				}
			} else {
				throw validationErr
			}
		}

		const retrieveCoords = (urlSearchCoords: string): void => {
			// checks whether /search is inside
			if (urlSearchCoords.includes('/search')) {
				try {
					const initCoords = convertSearchCoords(urlSearchCoords)
					setSearchedLocCoords(initCoords)
				} catch (validationErr) {
					console.log(validationErr)
					// navigate back to default settings
					navigate(ScreenRoutePath.Map)
				}
			}
		}

		retrieveCoords(window.location.href)

		return () => {
			retrieveCoords(window.location.href)
		}
	}, [navigate])

	// handle google map interaction - go current loc
	const handleGoToCurrentLocation = useCallback(() => {
		if (!('geolocation' in navigator)) {
			setShowPermisModal(true)
			setPermisText('Please enable the location access for this browser in your device settings.')
			return
		}

		if (!isCurrentCoordsAvailable) {
			setShowPermisModal(true)
			setPermisText('Please enable the location access for this website on your browser.')
			return
		}

		mapRef.current?.setZoom(15)
		// if there is a drop pin being dropped, the center would be set to that
		searchedLocCoords
			? mapRef.current?.panTo(searchedLocCoords)
			: mapRef.current?.panTo(currentCoords)
		setSelectedLocation(null)
	}, [currentCoords, isCurrentCoordsAvailable, searchedLocCoords])

	// default to current location when opening map screen if location access is enabled
	useEffect(
		() => {
			const locationAccessEnabled = hasLocationPermissions && isCurrentCoordsAvailable

			// check whether is there any location pin to pan to
			if (searchedLocCoords && mapRef.current) {
				mapRef.current.setCenter(searchedLocCoords)
				mapRef.current.setZoom(15)
				return
			}
			if (locationAccessEnabled && !state) {
				setTimeout(() => {
					handleGoToCurrentLocation()
				}, 100)
			}
		},
		// Note: Omitted dependencies here because they break existing map behaviour.
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[
			mapRef.current,
			isCurrentCoordsAvailable
			// handleGoToCurrentLocation,
			// locationPermissionOn,
			// searchedLocCoords
			// state
		]
	)

	useEffect(() => {
		// set default popup height
		if (defaultPopupHeight === 0) {
			setDefaultPopupHeight(locDetailsRef.current?.clientHeight ?? 0)
		}

		// clear history location state on reload
		window.addEventListener('beforeunload', () => {
			navigate(ScreenRoutePath.Map, {})
		})
		return () => {
			window.removeEventListener('beforeunload', () => {
				navigate(ScreenRoutePath.Map, {})
			})
		}
	}, [defaultPopupHeight, navigate])

	// when redirect back from charging screens
	useEffect(() => {
		if (state) {
			const location = state.location
			setSelectedLocation(location)
			setTimeout(() => {
				setCenterOffset((locDetailsRef.current?.clientHeight ?? 0) / 2 - 25)
				setCenter(formatLocCoords(location))
			}, 100)
		}
	}, [state])

	useEffect(() => {
		const lastAppliedFilters = JSON.parse(
			sessionStorage.getItem(LAST_APPLIED_FILTERS_KEY) ?? 'null'
		) as Filter | null
		if (lastAppliedFilters) {
			setFilters((prevState) => ({
				...prevState,
				powerTypeFilter: lastAppliedFilters.powerTypeFilter,
				cpoFilter: lastAppliedFilters.cpoFilter
			}))
		}
	}, [])

	// if selectedlocation is not null, set it to the latest location to get latest location status
	useEffect(() => {
		if (selectedLocation) {
			const newSelectedLocation = filteredLocations.find(
				(location) => location.uid === selectedLocation.uid
			)
			if (
				newSelectedLocation &&
				JSON.stringify(newSelectedLocation) !== JSON.stringify(selectedLocation)
			) {
				setSelectedLocation(newSelectedLocation)
			}
		}
	}, [filteredLocations, selectedLocation])

	const handleGoCurLoc = () => {
		setLocationName(null)
		setSearchedLocCoords(null)
		setFilters({ ...filters, distHighest: Number.MAX_SAFE_INTEGER })
		handleGoToCurrentLocation()
	}

	const handleBottomPopupClose = () => {
		navigate(ScreenRoutePath.Map, {})
		setCenterOffset(defaultPopupHeight / 2 - 25)
		setCenter(formatLocCoords(selectedLocation))
		setSelectedLocation(null)
	}

	const handleClickApplyFilters = (filters: Filter) => {
		setFilters(filters)
		sessionStorage.setItem(
			LAST_APPLIED_FILTERS_KEY,
			JSON.stringify({
				powerTypeFilter: filters.powerTypeFilter,
				cpoFilter: filters.cpoFilter
			})
		)
	}

	const handlePowerTypeFilterChange = (selectedType: PowerType) => {
		const powerTypeFilter = filters.powerTypeFilter === selectedType ? null : selectedType
		setFilters((prevFilters) => ({
			...prevFilters,
			powerTypeFilter
		}))
		sessionStorage.setItem(
			LAST_APPLIED_FILTERS_KEY,
			JSON.stringify({
				powerTypeFilter,
				cpoFilter: filters.cpoFilter
			})
		)
	}

	const handleSetZoom = useCallback((zoom: number) => {
		setZoom(zoom)
	}, [])

	const onShowChargerScreenHandler = (showChargerScreen: boolean) => {
		setShowSearchChargerScreen(showChargerScreen)
	}

	const handleSelectLocation = (location: OmniLocation) => {
		setSelectedLocation(location)
		setTimeout(() => {
			/*
      The intention behind this offset calculation is to prevent marker from being positioned directly at
      the center of the map where they might be obscured by the location details popup

      locDetailsRef.current.offsetHeight / 2: This calculates half of the height of the bottom container (location details popup). This value represents the vertical center of the container.
      - 25: This subtracts an additional manual value from the calculated center to ensure the marker is centered properly above the popup.
    */
			// need to pass in the lat and lng of the intended location
			if (mapRef.current) {
				const properPoint = latLng2Point(
					{
						lat: parseFloat(location.coordinates.latitude),
						lng: parseFloat(location.coordinates.longitude)
					},
					mapRef.current,
					17
				)
				const offsetPoint = {
					x: properPoint?.x ?? 0,
					y: properPoint?.y ?? 0 + (locDetailsRef.current?.offsetHeight ?? 0) / 2 - 25
				}
				const offsetLatLng = {
					lat: point2LatLng(offsetPoint, mapRef.current, 17)?.lat() ?? 0,
					lng: point2LatLng(offsetPoint, mapRef.current, 17)?.lng() ?? 0
				}
				mapRef.current.setZoom(17)
				miniPanTo([], [], offsetLatLng, mapRef.current, 10, 2)
			}
		}, 0)
	}

	const updateMapRef = (map: google.maps.Map, maps: typeof google.maps) => {
		/*
    map refers to the Google Maps instance which provide methods to interact with the map
    maps refers to the collection of services & libraries provider by Google Maps such as places, marker, etc
  */
		mapRef.current = map
		mapsRef.current = maps
	}

	const alertToShow = useMemo(() => {
		if (locationsLoading && !selectedLocation) {
			return { showAlert: true, msg: 'Loading locations ...' }
		}

		if (searchedLocCoords && filteredLocations.length === 0) {
			return { showAlert: true, msg: 'No nearby chargers found' }
		}

		if (
			JSON.stringify(filters) !== JSON.stringify(INITIAL_FILTER_STATE) &&
			filteredLocations.length === 0
		) {
			return {
				showAlert: true,
				msg: 'No results matched your filter criteria'
			}
		}

		return { showAlert: false, msg: '' }
	}, [searchedLocCoords, filteredLocations, filters, locationsLoading, selectedLocation])

	const handleDropPinClick = () => {
		setCenter(searchedLocCoords)
		setCenterOffset(0)
		setSelectedLocation(null)
	}

	const onCloseButtonClicked = () => {
		window.history.pushState({}, '', '/map')
		setLocationName(null)
		setSearchedLocCoords(null)
		localStorage.removeItem(LAST_LOCATION_DROPPED_PIN_NAME)
	}

	return (
		<LazyMotion features={domAnimation}>
			{showListScreen && (
				<div className="absolute z-50 h-full w-full bg-white">
					<ListViewScreen
						map={mapRef.current}
						maps={mapsRef.current}
						filters={filters}
						locationsLoading={locationsLoading}
						locations={filterLocationsByDistance}
						onClearLocationPin={onCloseButtonClicked}
						backFunction={(coords: Coordinates) => {
							setShowListScreen(false)
							if (!(coords.lat === currentCoords.lat && coords.lng === currentCoords.lng)) {
								setZoom(18)
								setCenter(coords)
								setSearchedLocCoords(coords)
								setLocationName(localStorage.getItem(LAST_LOCATION_DROPPED_PIN_NAME))
								return
							}
							if (currentCoords.lat && currentCoords.lng) {
								mapRef.current?.panTo(currentCoords)
								return
							}
						}}
						onClickLoc={(location: OmniLocation) => {
							setSelectedLocation(location)
							setTimeout(() => {
								setCenterOffset((locDetailsRef.current?.clientHeight ?? 0) / 2 - 25)
								setZoom(17)
								setCenter(formatLocCoords(location))
							}, 100)
							setShowListScreen(false)
						}}
						locationName={locationName}
						onSetLocationTag={(name) => {
							setLocationName(name)
						}}
						onSetSearchCharger={onShowChargerScreenHandler}
						onSetNewCoords={(newSearchCoords) => {
							setSearchedLocCoords(newSearchCoords)
						}}
						currentCoords={searchedLocCoords ?? currentCoords}
						actualCoords={currentCoords}
						isCurrentCoordsAvailable={isCurrentCoordsAvailable}
						isAuthenticated={isAuthenticated}
						subscribedCpoEntities={user?.subscribedCpoEntities}
					/>
				</div>
			)}

			{showSearchScreen && (
				<div className="absolute z-[60] h-full w-full bg-white">
					<SearchChargerScreen
						map={mapRef.current}
						maps={mapsRef.current}
						actualCoords={currentCoords}
						currentCoords={currentCoords}
						isCurrentCoordsAvailable={isCurrentCoordsAvailable}
						backFunction={() => {
							setShowSearchScreen(false)
						}}
						onClickLoc={(coords?: Coordinates, name?: string) => {
							if (coords) {
								setZoom(18)
								setCenter(coords)
								setShowSearchScreen(false)
								// check whether if coordinates are the same as the current location
								if (coords.lat === currentCoords.lat && coords.lng === currentCoords.lng) {
									setSearchedLocCoords(null)
									setLocationName(null)
									return
								}
								// if there is a given location name
								if (name) {
									setSearchedLocCoords(coords)
									const encodedCoords = `${encodeURIComponent(
										coords.lat
									)}-${encodeURIComponent(coords.lng)}`
									window.history.pushState(
										{ lat: coords.lat, lng: coords.lng },
										'',
										`/search/${encodedCoords}`
									)
									localStorage.setItem(LAST_LOCATION_DROPPED_PIN_NAME, name)
									setLocationName(name)
								}
							}
						}}
					/>
				</div>
			)}

			<PageContainer scroll={false} className="relative">
				<FilterPopup
					showPopup={showFilterPopup}
					showDistFilter={
						typeof currentCoords.lat === 'number' && typeof currentCoords.lng === 'number'
					}
					closePopup={() => {
						setShowFilterPopup(false)
					}}
					minDistance={FILTER_SETTINGS.minDistance}
					maxDistance={FILTER_SETTINGS.maxDistance}
					filtersVal={filters}
					onClickApply={(filters: Filter) => {
						handleClickApplyFilters(filters)
					}}
					cpoList={cpoListMemo}
					filterType={filterType}
				/>
				<CarparkRatesModal
					carparkRates={getCarparkRates(selectedLocation)}
					address={selectedLocation?.address}
					postalCode={selectedLocation?.postal_code}
					name={selectedLocation?.name}
					showModal={showCarparkRates}
					closeModal={() => {
						setShowCarparkRates(false)
					}}
				/>
				{/* modal to handle location permission errors */}
				<Modal
					text={permisText}
					buttonType="CLOSE"
					buttonTitle="Close"
					showModal={showPermisModal}
					onCloseModal={() => {
						setShowPermisModal(false)
					}}
				/>
				<Topbar
					showLogo
					showLoginIcon={!isAuthenticated}
					loginFunction={() => {
						navigate(ScreenRoutePath.AccountLogin)
					}}
					filterFunction={() => {
						setShowFilterPopup(true)
					}}
					className="max-w-full shadow-md"
				/>
				{!showSearchChargerScreen && (
					<Filters
						onSelectFilter={(filterType) => {
							setFilterType(filterType)
							if (filterType === FilterType.CPO || filterType === FilterType.ALL) {
								setShowFilterPopup(true)
							}
						}}
						filters={filters}
						onPowerTypeFilterChange={handlePowerTypeFilterChange}
						isListView={showListScreen}
					/>
				)}

				{/* Live Charging Session Button */}
				{!!targetChargerDetails && (
					<LiveSession dataTestIdPrefix="ms" chargerRouterDetails={targetChargerDetails} />
				)}
				<Alert
					isLiveSession={!!targetChargerDetails}
					showAlert={alertToShow.showAlert}
					msg={alertToShow.msg}
				/>
				{/* map */}
				<div
					style={{
						width: '100%',
						height: '100%',
						paddingTop: TOPBAR_HEIGHT
					}}
				>
					<Map
						zoom={zoom}
						center={center}
						mapType={mapType}
						centerOffset={centerOffset}
						isCurrentCoordsAvailable={isCurrentCoordsAvailable}
						currentCoords={currentCoords}
						locations={state?.location ? [state.location] : filteredLocations}
						cpoLocations={cpoLocationMap}
						searchLocation={searchedLocCoords}
						onClickMarker={(location: OmniLocation) => {
							handleSelectLocation(location)
						}}
						onSetZoom={handleSetZoom}
						mapRef={mapRef}
						updateMapRef={updateMapRef}
						onSetSearchLocation={handleDropPinClick}
					/>
				</div>
				{/* entire bottom component */}
				<MapBottomContainer
					stopTouchProp={selectedLocation === null || stopTouchProp}
					onClose={handleBottomPopupClose}
				>
					<img src={VoltalityWatermark} className="relative top-[10.5rem] rounded" />
					{(!selectedLocation || showListScreen) && (
						<MapButtons
							onClickCurLoc={handleGoCurLoc}
							onClickList={() => {
								setShowListScreen(true)
							}}
							onClickMapType={() => {
								setMapType((prev) => (prev = prev !== 2 ? prev + 1 : 0))
							}}
							onClickQrScanner={() => {
								navigate(ScreenRoutePath.CheckIn)
							}}
						/>
					)}

					{/* bottom popups container */}
					<div
						ref={locDetailsRef}
						className="pointer-events-auto z-10 flex h-fit w-full flex-col items-center justify-start rounded-t-lg bg-white"
					>
						{/* location details popup when selected location */}
						{selectedLocation && !showListScreen && (
							<LocationDetailsPopup
								isLiveSession={!!targetChargerDetails}
								currentCoords={currentCoords}
								powerTypeFilter={filters.powerTypeFilter}
								showCarparkRates={() => {
									setShowCarparkRates(true)
								}}
								selectedLocation={selectedLocation}
								cpoLocations={cpoLocationHandler(cpoLocationMap, selectedLocation.operator.name)}
								onStopTouchProp={(bool) => {
									setStopTouchProp(bool)
								}}
								onClose={handleBottomPopupClose}
								isAuthenticated={isAuthenticated}
							/>
						)}

						{/* default popup when no selected location */}
						{!selectedLocation && (
							<>
								<DefaultMapPopup
									locationName={locationName}
									onClickSearch={() => {
										setShowSearchScreen(true)
									}}
									onClickClose={onCloseButtonClicked}
								/>
								<Bottombar selectedRoute="Map" />
							</>
						)}
					</div>
				</MapBottomContainer>
			</PageContainer>
		</LazyMotion>
	)
}
