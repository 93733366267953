export enum OmniConnectorFormat {
	Cable = 'CABLE',
	Socket = 'SOCKET'
}

export enum OmniConnectorPowerType {
	AcOnePhase = 'AC_1_PHASE',
	AcTwoPhase = 'AC_2_PHASE',
	AcTwoPhaseSplit = 'AC_2_PHASE_SPLIT',
	AcThreePhase = 'AC_3_PHASE',
	Dc = 'DC'
}

export enum OmniConnectorPrivilegeStatus {
	Private = 'PRIVATE',
	Privileged = 'PRIVILEGED',
	Public = 'PUBLIC'
}

/**
 * OCPI 3.0-2 Functional Use Cases > 5.4.12 `ConnectorType` `OpenEnum`
 * @see {@link https://evroaming.org/wp-content/uploads/2024/10/OCPI-3.0-v1-review.zip}
 */
export enum OmniConnectorStandard {
	// Common EV Charging Connector Types
	Chademo = 'CHADEMO',
	Iec62196T1 = 'IEC_62196_T1',
	Iec62196T1Combo = 'IEC_62196_T1_COMBO',
	Iec62196T2 = 'IEC_62196_T2',
	Iec62196T2Combo = 'IEC_62196_T2_COMBO',
	Mcs = 'MCS',
	SaeJ3400 = 'SAE_J3400',
	TeslaS = 'TESLA_S',
	// Domestic and Industrial Connector Types
	DomesticA = 'DOMESTIC_A',
	DomesticB = 'DOMESTIC_B',
	DomesticC = 'DOMESTIC_C',
	DomesticD = 'DOMESTIC_D',
	DomesticE = 'DOMESTIC_E',
	DomesticF = 'DOMESTIC_F',
	DomesticG = 'DOMESTIC_G',
	DomesticH = 'DOMESTIC_H',
	DomesticI = 'DOMESTIC_I',
	DomesticJ = 'DOMESTIC_J',
	DomesticK = 'DOMESTIC_K',
	DomesticL = 'DOMESTIC_L',
	DomesticM = 'DOMESTIC_M',
	DomesticN = 'DOMESTIC_N',
	DomesticO = 'DOMESTIC_O',
	Iec603092Single16 = 'IEC_60309_2_SINGLE_16',
	Iec603092Three16 = 'IEC_60309_2_THREE_16',
	Iec603092Three32 = 'IEC_60309_2_THREE_32',
	Iec603092Three64 = 'IEC_60309_2_THREE_64',
	Nema520 = 'NEMA_5_20',
	Nema630 = 'NEMA_6_30',
	Nema650 = 'NEMA_6_50',
	Nema1030 = 'NEMA_10_30',
	Nema1050 = 'NEMA_10_50',
	Nema1430 = 'NEMA_14_30',
	Nema1450 = 'NEMA_14_50',
	//  Legacy and Novelty Connector Types
	GbtAc = 'GBT_AC',
	GbtDc = 'GBT_DC',
	Iec62196T3A = 'IEC_62196_T3A',
	Iec62196T3C = 'IEC_62196_T3C',
	PantographBottomUp = 'PANTOGRAPH_BOTTOM_UP',
	PantographTopDown = 'PANTOGRAPH_TOP_DOWN',
	TeslaR = 'TESLA_R'
}

export enum OmniConnectorStatus {
	Available = 'AVAILABLE',
	Blocked = 'BLOCKED',
	Charging = 'CHARGING',
	Finishing = 'FINISHING',
	Inoperative = 'INOPERATIVE',
	OutOfOrder = 'OUTOFORDER',
	Planned = 'PLANNED',
	Preparing = 'PREPARING',
	Removed = 'REMOVED',
	Reserved = 'RESERVED',
	Unknown = 'UNKNOWN'
}
