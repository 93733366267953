import { useEffect } from 'react'

import { LANGUAGE_KEY } from '../constants/storage'
import { Language } from '../enums/env'
import { isValidLanguage } from '../utils/string'
import { useLocalStorageItem } from './useStorageItem'

/**
 * Returns the currently set `lang`.
 */
const useLanguage = (): Language => {
	const [language, setLanguage] = useLocalStorageItem(LANGUAGE_KEY)

	const languageQueryParameter = new URL(window.location.href).searchParams.get(LANGUAGE_KEY)

	/**
	 * If a valid `lang` query parameter is provided,
	 * replace the current value found in `localStorage`.
	 */
	useEffect((): void => {
		if (isValidLanguage(languageQueryParameter)) {
			setLanguage(languageQueryParameter)
		}
	}, [languageQueryParameter, setLanguage])

	if (isValidLanguage(languageQueryParameter)) {
		return languageQueryParameter
	} else if (isValidLanguage(language)) {
		return language
	}
	return Language.English
}

export default useLanguage
