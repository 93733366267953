import { SVGProps, memo } from 'react'

const ChargerDcIcon = ({
	fill = 'none',
	stroke = 'currentColor',
	viewBox = '0 0 20 28',
	xmlns = 'http://www.w3.org/2000/svg',
	...props
}: SVGProps<SVGSVGElement>) => {
	return (
		<svg fill={fill} stroke={stroke} viewBox={viewBox} xmlns={xmlns} {...props}>
			<path d="M10 13C11.1046 13 12 12.1046 12 11C12 9.89543 11.1046 9 10 9C8.89543 9 8 9.89543 8 11C8 12.1046 8.89543 13 10 13Z" />
			<path d="M13.5 8C14.3284 8 15 7.32843 15 6.5C15 5.67157 14.3284 5 13.5 5C12.6716 5 12 5.67157 12 6.5C12 7.32843 12.6716 8 13.5 8Z" />
			<path d="M6.5 8C7.32843 8 8 7.32843 8 6.5C8 5.67157 7.32843 5 6.5 5C5.67157 5 5 5.67157 5 6.5C5 7.32843 5.67157 8 6.5 8Z" />
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				strokeWidth="1.5"
				strokeLinejoin="round"
				d="M5.0187 1H15.1211C15.1211 1 16.1804 1.71141 16.6267 2.20023C17.0729 2.68906 17.4981 3.34629 17.7952 3.87727C18.0924 4.40824 18.3331 4.88384 18.5381 5.50037C18.743 6.1169 18.9308 6.75927 18.9837 7.56611C19.0367 8.37295 18.9597 9.47695 18.7609 10.2958C18.5621 11.1147 18.1928 11.8599 17.8695 12.4354C17.5463 13.0108 17.3095 13.3024 16.9038 13.7633C16.4982 14.2243 15.9652 14.7442 15.4182 15.1651C14.8712 15.586 14.2957 15.9712 13.6354 16.2717C12.9752 16.5723 11.4812 16.9357 11.4812 16.9357C11.4812 16.9357 9.45835 17.2052 7.84143 16.6406C7.19873 16.4162 6.77758 16.3397 6.05866 15.9766C5.15805 15.5218 4.29065 14.9452 3.60735 14.206C2.66823 13.1901 1.91559 11.9651 1.45316 10.6647C1.08699 9.63499 0.967074 8.50989 1.00747 7.41856C1.03816 6.58952 1.23339 5.76042 1.52745 4.98393C1.77721 4.3244 2.20103 3.69 2.5674 3.13952C2.92579 2.60101 3.55685 2.01067 3.97876 1.66399C4.40066 1.31731 5.0187 1 5.0187 1Z"
			/>
			<path d="M6.29869 24.735C7.56822 24.735 8.59738 23.6904 8.59738 22.4019C8.59738 21.1133 7.56822 20.0687 6.29869 20.0687C5.02916 20.0687 4 21.1133 4 22.4019C4 23.6904 5.02916 24.735 6.29869 24.735Z" />
			<path d="M13.8104 24.6663C15.0799 24.6663 16.1091 23.6217 16.1091 22.3331C16.1091 21.0446 15.0799 20 13.8104 20C12.5409 20 11.5117 21.0446 11.5117 22.3331C11.5117 23.6217 12.5409 24.6663 13.8104 24.6663Z" />
			<path
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
				d="M14.5599 18H5.44006C2.98788 18 1 20.0147 1 22.5C1 24.9853 2.98788 27 5.44006 27H14.5599C17.0121 27 19 24.9853 19 22.5C19 20.0147 17.0121 18 14.5599 18Z"
			/>
			<path strokeWidth="1.5" d="M6 17H14" />
		</svg>
	)
}

const MemoisedChargerDcIcon = memo(ChargerDcIcon)

export default MemoisedChargerDcIcon
